import { useContext, useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";
import { FluentProvider, webLightTheme } from '@fluentui/react-components';

import "./index.css";

import Layout from "./pages/layout/Layout";
import NoPage from "./pages/NoPage";
import { AppStateProvider } from "./state/AppProvider";
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider, AuthenticatedTemplate, useMsal, UnauthenticatedTemplate } from '@azure/msal-react';
import Login from "./pages/login/Login";
import { chatTip } from "./api";
import AdminPage from "./pages/admin/AdminPage";
import { AssistantForm } from "./pages/admin/assistants/AssistantForm";
import Assistant from "./pages/chat/Assistant";
import Home from "./pages/home/Home";
import { AbilityContext } from './api/security/contextualPermission';
import { defineAbilityFor } from "./api/security/permissions";
import { ModelForm } from "./pages/admin/models/ModelForm";
import { RoleForm } from "./pages/admin/roles/RoleForm";
import LoginTeamsStart from "./pages/login/LoginTeamsStart";
import LoginTeamsEnd from "./pages/login/LoginTeamsEnd";
import Panel from "./pages/Panel";

interface AppProps {
  instance: PublicClientApplication;
}

initializeIcons();

const MainContent = () => {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const [roles, setRoles] = useState<string[]>([]);
  const ability = useContext(AbilityContext);
  const [updatedAbility, setUpdatedAbility] = useState(false);

  useEffect(() => {
    if (activeAccount) {
      const newRoles = activeAccount.idTokenClaims?.roles;

      if (newRoles) {
        // Only update the state if the roles have changed
        if (JSON.stringify(newRoles) !== JSON.stringify(roles)) {
          setRoles(newRoles);
        }
      }
    } else {
      console.warn("No active account... Application must reauthenticate to continue.");
    }
  }, [activeAccount, instance, setRoles]);

  useEffect(() => {
    if (roles.length > 0) {
      ability.update(defineAbilityFor(roles).rules);
      setUpdatedAbility(true);
      console.log(roles)
    }
  }, [roles]);

  return <FluentProvider theme={webLightTheme} style={{ width: '100%' }}>
    <AuthenticatedTemplate>
      <AppStateProvider>
        <AbilityContext.Provider value={ability}>
          <Routes>
            {activeAccount && updatedAbility && <>
              <Route path="/" element={<Panel />} />
              <Route path="/:assistantId" element={<Panel />} />
              <Route path="/assistant-chat" element={<Layout />}>
                <Route path="/assistant-chat/:assistantId" element={<Assistant chatText={chatTip('/')} />} />
              </Route>
              {
                ability.can('create', 'Assistant') && (
                  <>
                    <Route path="/admin" element={< AdminPage />} />
                    <Route path="/admin/assistants/form" element={< AssistantForm />} />
                    <Route path="/admin/assistants/form" element={< AssistantForm />} />
                    <Route path="/admin/models/form" element={< ModelForm />} />
                    <Route path="/admin/roles/form" element={< RoleForm />} />
                  </>
                )
              }
            </>}
            <Route path="/teams-login" element={<LoginTeamsEnd />} />
            <Route path="/login" element={<Login />} />
            {!activeAccount && <Route path="*" element={<Login />} />}
            {activeAccount && <Route path="*" element={<NoPage />} />}
          </Routes>
        </AbilityContext.Provider>
      </AppStateProvider>
    </AuthenticatedTemplate>
    <UnauthenticatedTemplate>
      <Routes>
        <Route path="/teams-login" element={<LoginTeamsStart />} />
        <Route path="/teams-login-end" element={<LoginTeamsEnd />} />
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<Login />} />
      </Routes>
    </UnauthenticatedTemplate>
  </FluentProvider>;
}

/**
 * msal-react is built on the React context API and all parts of your app that require authentication must be
 * wrapped in the MsalProvider component. You will first need to initialize an instance of PublicClientApplication
 * then pass this to MsalProvider as a prop. All components underneath MsalProvider will have access to the
 * PublicClientApplication instance via context as well as all hooks and components provided by msal-react. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const App = ({ instance }: AppProps) => {
  return (
    <MsalProvider instance={instance}>
      <MainContent />
    </MsalProvider>
  );
};

export default App;