import {
  SearchFilled,
  EditRegular,
  DeleteRegular,
} from "@fluentui/react-icons";
import {
  TableBody,
  TableCell,
  TableRow,
  Table,
  TableHeader,
  TableHeaderCell,
  TableCellLayout,
  Input,
  Label,
  Button,
  useArrowNavigationGroup,
  useFocusableGroup,
  useId,
  InputProps,
  Skeleton,
  SkeletonItem,
} from "@fluentui/react-components";
import _ from 'lodash';
import styles from './TableComponent.module.css';
import { useEffect, useState } from "react";
import { SearchField } from "../SearchField/SearchField";


interface TableComponentProps {
  items: any[];
  columns: {
    columnKey: string;
    label: string;
    attribute?: string;
    filterable?: boolean;
  }[];
  onEditFunction?: (item: any) => void;
  onDeleteFunction?: (item: any) => void;
  loading?: boolean;
}

export const TableComponent = (props: TableComponentProps) => {
  const [value, setValue] = useState<string>("");
  const [filteredItems, setFilteredItems] = useState(props.items);
  const keyboardNavAttr = useArrowNavigationGroup({ axis: "grid" });
  const focusableGroupAttr = useFocusableGroup({
    tabBehavior: "limited-trap-focus",
  });
  const beforeId = useId("content-before");

  const onChange: InputProps["onChange"] = (ev, data) => {
    // The controlled input pattern can be used for other purposes besides validation,
    // but validation is a useful example
    if (data.value.length <= 20) {
      setValue(data.value);
    }
  };

  const filterItems = (searchValue: string) => {
    const newFilteredItems = props.items.filter(item => {
      return props.columns.some(column => {
        if (column.filterable && column.attribute) {
          const itemValue = _.get(item, column.attribute);
          return itemValue && itemValue.toString().toLowerCase().includes(searchValue.toLowerCase());
        }
        return false;
      });
    });
    console.log(newFilteredItems);
    setFilteredItems(newFilteredItems);
  };

  useEffect(() => {
    filterItems(value);
  }, [value]);


  return (
    <>
      <SearchField id="search" value={value} onChange={onChange} />
      <Table
        {...keyboardNavAttr}
        role="grid"
        aria-label="Table with grid keyboard navigation"
        className={styles.table}
      >
        <TableHeader className={styles.tableHead}>
          <TableRow>
            {props.columns.map((column) => (
              <TableHeaderCell key={column.columnKey}>
                {column.label}
              </TableHeaderCell>
            ))}
          </TableRow>
        </TableHeader >
        <TableBody>
          {props.loading && (
            <TableRow>
              {props.columns.map((column, i) => {
                return (
                  <TableCell key={`cell-${i}`} tabIndex={0} role="gridcell">
                    <Skeleton key={`skeleton-${i}`} appearance="opaque" animation="wave">
                      <SkeletonItem className={styles.skeleton} />
                    </Skeleton>
                  </TableCell>
                )
              })}
            </TableRow>
          )
          }
          {filteredItems.map((item, i) => (
            <TableRow key={`row-${i}`} >
              {props.columns.map((column, i) => {
                if (column.attribute) {
                  return (
                    <TableCell key={`cell-${i}`} tabIndex={0} role="gridcell">
                      {_.get(item, column.attribute)}
                    </TableCell>
                  )
                }
              })}
              <TableCell role="gridcell" tabIndex={0} {...focusableGroupAttr}>
                <TableCellLayout>
                  <Button icon={<EditRegular />} aria-label="Edit" onClick={() => props.onEditFunction && props.onEditFunction(item)} />
                  <Button icon={<DeleteRegular />} aria-label="Delete" onClick={() => props.onDeleteFunction && props.onDeleteFunction(item)} />
                </TableCellLayout>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>

  );
};