import { useMsal } from "@azure/msal-react";
import * as microsoftTeams from '@microsoft/teams-js';
import { useEffect } from "react";

export default function LoginTeamsEnd() {
  const { instance, inProgress, } = useMsal();

  useEffect(() => {
    console.log("handle redirect promise")
    microsoftTeams.app.initialize().then(() => {
      instance.handleRedirectPromise()
      .then(result => {
        console.log("handle redirect promise: sucesso")
        microsoftTeams.authentication.notifySuccess(JSON.stringify(result) || undefined);
      })
      .catch((error) => {
        console.log("handle redirect promise: falha")
        console.log(error)
        microsoftTeams.authentication.notifyFailure(error);
      })
    });
    
  }, []);


  return (
    <>
      <p>Auth end page</p>
    </>
  )
}