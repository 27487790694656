import Menu from "./menu";
import { Chat } from "./chat";
import { ThemeContextProvider } from "./Context/Theme";
import { ContentContainer } from "./style";
import { PropsWithChildren, useEffect, useState } from "react";
import { getAccessToken } from "../../authConfig";
import { handleGetTokenError } from "../../utils/auth";
import { AssistantModel, ChatMessage, listAssistantsApi } from "../../api";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import { Spinner } from "@fluentui/react";
import { ChatContext } from "./Context/Chat";

export default function Panel(props: PropsWithChildren<{}>) {
    const { instance } = useMsal();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [assistants, setAssistants] = useState<AssistantModel[]>([]);
    const [messages, setMessages] = useState<ChatMessage[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        try {
            const fetchData = async () => {
                const token = await getAccessToken(instance);
                if (!token) {
                    handleGetTokenError(navigate)
                }

                const response = await listAssistantsApi(token);
                if (response?.body) {
                    const result: AssistantModel[] = await response.json();
                    setAssistants(result);
                }
                else {
                    setAssistants([]);
                }
                setIsLoading(false);
            };
            Promise.resolve(fetchData());
        } catch (error) {
            setIsLoading(false);
        }
    }, [])

    return (
        <ThemeContextProvider>
            <ChatContext.Provider value={{ messages, setMessages }}>
                <ContentContainer>
                    {!isLoading ?
                        <>
                            <Menu
                                assistants={assistants}
                            />
                            <Chat
                            />
                        </>
                        :
                        <div className="containerSpinner">
                            <Spinner />
                        </div>
                    }
                </ContentContainer>
            </ChatContext.Provider>
        </ThemeContextProvider>
    )
}
