import { Icon, Modal, Spinner, Stack } from "@fluentui/react"
import { ContentChat, Question, QuestionSuggestions, Welcome, ChatMessageContainer } from "./style"
import { PropsWithChildren, useContext, useEffect, useLayoutEffect, useRef, useState } from "react"
import lis from '../assets/lis.png';
import lisDark from '../assets/LisDark.png';
import { ThemeContext } from "../Context/Theme";
import { darkTheme, lightTheme } from "../../../styles/theme";
import { AppStateContext } from "../../../state/AppProvider";
import { AssistantChatMessage, AssistantConversationRequest, assistantFeedbackMessageApi, assistantKnownTopicsApi, AssistantModel, assistantSuggestPromptsApi, chatAssistantApi, ChatHistoryLoadingState, ChatMessage, ChatResponse, Citation, Conversation, CosmosDBStatus, ErrorMessage, FeedbackResponse, getAssistantByIdApi, knownTopicsApi, ResultModalProps, SuggestPrompts } from "../../../api";
import uuid from "react-uuid";
import { getAccessToken } from "../../../authConfig";
import { clearLocalStorageMSAL, handleGetTokenError } from "../../../utils/auth";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useBoolean } from "@fluentui/react-hooks";
import { Answer } from "../../../components/Answer";
import { ErrorCircleRegular, SquareRegular } from "@fluentui/react-icons";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import supersub from "remark-supersub";
import { InfoModal } from "../style";
import { Modal as ComponentsModal } from "../../../components/modal/Modal";
import { useMsal } from "@azure/msal-react";
import { formatDate } from "../../../utils/formatDate";
import rehypeRaw from "rehype-raw";
import { ChatContext } from "../Context/Chat";

const enum messageStatus {
    NotRunning = "Not Running",
    Processing = "Processing",
    Done = "Done"
}

export function Chat(props: PropsWithChildren<{ idx?: string }>) {
    const location = useLocation()
    const assistantState: AssistantModel = location.state?.assistant || null
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isModalOpenQuotes, setIsModalOpenQuotes] = useState(false)
    const { assistantId } = useParams()
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null)
    const [assistant, setAssistant] = useState<AssistantModel | null>(assistantState)
    const [question, setQuestion] = useState<string>("")
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [showLoadingMessage, setShowLoadingMessage] = useState<boolean>(false)
    const [isLoadingAssistant, setLoadingAssistant] = useState(true)
    const [isLoadingSuggestPrompts, setLoadingSuggestPrompts] = useState(false)
    const [topics, setTopics] = useState<string>("")
    const [errorMsg, setErrorMsg] = useState<ErrorMessage | null>()
    const [loadedInfo, setLoadedInfo] = useState<boolean>(false);
    const [activeCitation, setActiveCitation] = useState<[content: string, id: string, title: string, filepath: string, url: string, metadata: string]>()
    const [processMessages, setProcessMessages] = useState<messageStatus>(messageStatus.NotRunning)
    const [hideErrorDialog, { toggle: toggleErrorDialog }] = useBoolean(true)
    const [modalState, setModalState] = useState<ResultModalProps>({ showResult: false, titleResult: "", result: "", typeResult: undefined })
    const abortFuncs = useRef([] as AbortController[])
    const { instance } = useMsal();
    const { isDarkTheme } = useContext(ThemeContext)
    const appStateContext = useContext(AppStateContext)
    const navigate = useNavigate()
    const [suggestions, setSuggestions] = useState<string[]>([])
    const { messages, setMessages } = useContext(ChatContext)

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen)
    }

    const toggleModalQuotes = () => {
        setIsModalOpenQuotes(!isModalOpenQuotes)
    }

    const makeApiRequest = async (question: string, conversationId?: string) => {
        if (!assistant) { return; }

        setIsLoading(true);
        setShowLoadingMessage(true);
        const abortController = new AbortController();
        abortFuncs.current.unshift(abortController);

        const userMessage: ChatMessage = {
            id: uuid(),
            role: "user",
            content: question,
            date: new Date().toISOString(),
        };

        let conversation: Conversation | null | undefined;
        if (!conversationId) {
            conversation = {
                id: conversationId ?? uuid(),
                title: question,
                messages: [...messages, userMessage],
                date: new Date().toISOString(),
                assistant_id: assistant?.id
            }
        } else {
            conversation = appStateContext?.state?.currentChat
            if (!conversation) {
                setIsLoading(false);
                setShowLoadingMessage(false);
                abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
                return;
            } else {
                conversation.messages.push(userMessage);
            }
        }

        appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: conversation });
        setMessages(conversation.messages)

        const request: AssistantConversationRequest = {
            messages: [...conversation.messages.filter((answer) => answer.role !== "error")],
            history_metadata: {
                conversation_id: conversationId ? conversationId : null,
            },
            assistant: assistant
        };

        let result = {} as ChatResponse;
        try {
            const token = await getAccessToken(instance);

            if (!token) {
                handleGetTokenError(navigate)
            }

            const response = await chatAssistantApi(request, abortController.signal, token);
            if (response?.body) {
                if (response.headers?.get("Content-Type") == "application/json") {
                    let auxResult = await response.json();
                    auxResult.choices[0].messages.forEach((obj: ChatMessage) => {
                        obj.id = uuid();
                        obj.date = new Date().toISOString();
                    })
                    result = auxResult;
                }
                else {
                    const reader = response.body.getReader();
                    let runningText = "";

                    while (true) {
                        setProcessMessages(messageStatus.Processing)
                        const { done, value } = await reader.read();
                        if (done) break;

                        var text = new TextDecoder("utf-8").decode(value);
                        const objects = text.split("\n");
                        objects.forEach((obj) => {
                            try {
                                runningText += obj;
                                result = JSON.parse(runningText);

                                if (conversation) {
                                    conversation.id = result.history_metadata.conversation_id;
                                    conversation.date = result.history_metadata.date;
                                    conversation.title = result.history_metadata.title;
                                }

                                result.choices[0].messages.forEach((obj) => {
                                    obj.id = obj.id;
                                    obj.date = obj.date;
                                    obj.user_feedback = null;
                                })
                                setShowLoadingMessage(false);
                                /** @ts-ignore */
                                setMessages([...conversation?.messages, ...result.choices[0].messages]);
                                runningText = "";
                            }
                            catch { }
                        });
                    }
                }
                conversation.messages.push(...result.choices[0].messages)
                appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: conversation });
                setMessages(conversation.messages);
            }

        } catch (e) {
            if (!abortController.signal.aborted) {
                let errorMessage = "An error occurred. Please try again. If the problem persists, please contact the site administrator.";
                if (result.error?.message) {
                    errorMessage = result.error.message;
                }
                else if (typeof result.error === "string") {
                    errorMessage = result.error;
                }
                let errorChatMsg: ChatMessage = {
                    id: uuid(),
                    role: "error",
                    content: errorMessage,
                    date: new Date().toISOString(),
                }
                conversation.messages.push(errorChatMsg);
                appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: conversation });
                setMessages([...messages, errorChatMsg]);
            } else {
                setMessages([...messages, userMessage])
            }
        } finally {
            setIsLoading(false);
            setShowLoadingMessage(false);
            abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
            setProcessMessages(messageStatus.Done)
        }

        return abortController.abort();
    }

    const onQuestionChange = (newValue?: string) => {
        setQuestion(newValue || "")
    }

    function chatStart() {
        if (assistant?.start_message) {
            var initialmessages = [{
                id: uuid(),
                role: "assistant",
                content: assistant.start_message,
                date: new Date().toISOString(),
                user_feedback: null
            }]

            var conversation: Conversation = {
                id: null,
                title: 'chat consultor',
                messages: initialmessages,
                date: new Date().toISOString(),
                assistant_id: assistant.id
            }
            appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: conversation });
            setMessages(conversation.messages);
        }
    }

    const sendQuestion = (title?: string) => {
        if (isLoading || (!title && !question.trim())) {
            return;
        }

        const conversationId = appStateContext?.state.currentChat?.id ? appStateContext?.state.currentChat?.id : undefined
        if (conversationId) {
            makeApiRequest(title || question, conversationId);
        } else {
            makeApiRequest(title || question);
        }
        setQuestion('')
    }

    const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
        if (ev.key === "Enter" && !ev.shiftKey) {
            ev.preventDefault()
            sendQuestion()
        }
    }

    const onShowCitation = (citation: Citation) => {
        setActiveCitation([citation.content, citation.id, citation.title ?? "", citation.filepath ?? "", citation.url ?? "", ""]);
        setIsModalOpenQuotes(true)
    }

    const stopGenerating = () => {
        abortFuncs.current.forEach(a => a.abort());
        setShowLoadingMessage(false);
        setIsLoading(false);
    }

    const parseCitationFromMessage = (message: ChatMessage) => {
        if (message?.role && message?.role === "assistant") {
            try {
                const toolMessage = message as AssistantChatMessage;
                return toolMessage.sources;
            }
            catch {
                return [];
            }
        }
        return [];
    }

    const message_feedback_handler = async (message_id: string | undefined, feedback: boolean) => {
        if (!message_id || !assistant || !assistant.id) {
            return
        }

        const options = {
            id: message_id,
            user_feedback: feedback,
            assistant_id: assistant?.id
        }

        const token = await getAccessToken(instance)

        if (!token) {
            handleGetTokenError(navigate)
        }

        const response = await assistantFeedbackMessageApi(options, token)

        const message_feedback: FeedbackResponse = await response.json()

        const newMessages = messages.map(message =>
            message.id === message_id
                ? { ...message, user_feedback: message_feedback.user_feedback }
                : message
        )

        let conversation = appStateContext?.state?.currentChat

        if (conversation) {
            conversation.messages = newMessages
            appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: conversation })
            setMessages(newMessages)
        }
    }

    const inIframe = () => {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }

    const returnReactMarkdownObject = () => {
        let objectReturn = <Stack className="spinnerContainer"><Spinner /></Stack>

        if (!isLoading) {
            objectReturn = <ReactMarkdown
                linkTarget="_blank"
                remarkPlugins={[remarkGfm, supersub]}
                children={topics}
                className="answerText"
            />
        }

        return objectReturn;
    }

    const makeKnownTopicsApiRequest = async () => {
        setIsLoading(true);
        let result = {} as ChatResponse;
        try {
            let response
            const token = await getAccessToken(instance);

            if (!token) {
                handleGetTokenError(navigate)
            }

            if (assistant) {
                response = await assistantKnownTopicsApi(token, assistant.id);
            } else if (props.idx) {
                response = await knownTopicsApi(props.idx, token);
            }
            if (response?.body) {
                if (response.headers?.get("Content-Type") == "application/json") {
                    result = await response.json();
                }
                else {
                    setIsLoading(false);
                    const reader = response.body.getReader();
                    let runningText = "";

                    while (true) {
                        const { done, value } = await reader.read();
                        if (done) break;

                        var text = new TextDecoder("utf-8").decode(value);
                        const objects = text.split("\n");
                        objects.forEach((obj) => {
                            try {
                                runningText += obj;
                                result = JSON.parse(runningText);
                                setTopics(result.choices[0].messages[0].content);
                                runningText = "";
                            }
                            catch { }
                        });
                    }
                }

                setTopics(result.choices[0].messages[0].content);
            }


        } catch (e) {
            let errorMessage = "Falha ao carregar assuntos conhecidos. Tente novamente, se o problema persistir, entre em contato com o administrador do sistema.";
            setTopics(errorMessage);
        } finally {
            setIsLoading(false);
        }
    }

    const newChat = () => {
        setProcessMessages(messageStatus.Processing)
        setMessages([])
        setIsModalOpenQuotes(false);
        setActiveCitation(undefined);
        const conversation = {
            id: null,
            title: 'Nova conversa',
            messages: [],
            date: new Date().toISOString(),
            assistant_id: assistant?.id
        }
        appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: conversation });
        setProcessMessages(messageStatus.Done)
        chatStart()
    };

    useEffect(() => {
        if (appStateContext?.state.isCosmosDBAvailable?.status === CosmosDBStatus.NotWorking && appStateContext?.state.chatHistoryLoadingState === ChatHistoryLoadingState.Fail && hideErrorDialog) {
            let subtitle = `${appStateContext?.state.isCosmosDBAvailable.status}. Please contact the site administrator.`
            setErrorMsg({
                title: "Chat history is not enabled",
                subtitle: subtitle
            })
            toggleErrorDialog();
        }
    }, [appStateContext?.state.isCosmosDBAvailable])

    useEffect(() => {
        if (appStateContext?.state.currentChat) {
            setMessages(appStateContext.state.currentChat.messages)
        } else {
            setMessages([])
        }
    }, [appStateContext?.state.currentChat])

    useLayoutEffect(() => {
        chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" })
    }, [showLoadingMessage, processMessages]);

    useEffect(() => {
        if (isModalOpen && !loadedInfo) {
            makeKnownTopicsApiRequest().then((r) => {
                console.log(r)
            }).catch((e) => {
                console.log(e)
            })
            setLoadedInfo(true);
        }

    }, [isModalOpen]);

    useEffect(() => {
        const fetchData = async () => {
            let response
            setLoadingSuggestPrompts(true);
            const token = await getAccessToken(instance);

            if (!token) {
                handleGetTokenError(navigate)
            }

            if (assistantId) {
                response = await assistantSuggestPromptsApi(token, assistantId);
            }

            if (response?.body) {
                const result: SuggestPrompts = await response.json();
                setSuggestions(result.promptSuggestions);
            }
            else {
                setSuggestions([]);
            }

            setLoadingSuggestPrompts(false);
        };
        fetchData();
    }, [assistantId, setSuggestions])

    useEffect(() => {
        try {
            setLoadingAssistant(true);
            newChat();
            async function fetchAssistant() {
                const token = await getAccessToken(instance);

                if (!token) {
                    handleGetTokenError(navigate);
                }

                if (assistantId) {
                    setModalState({ showResult: true, titleResult: "Aguarde", result: "Carregando assistente...", typeResult: "wait" });
                    const response = await getAssistantByIdApi(token, assistantId);

                    const handleBackToStart = () => { clearLocalStorageMSAL(); window.location.href = '/'; };

                    switch (response.status) {
                        case 200:
                            try {
                                const result: AssistantModel = await response.json();
                                setAssistant(result);
                                setModalState({ showResult: false, titleResult: "Aguarde", result: "Carregando assistente...", typeResult: "wait" });
                                setLoadingAssistant(false);
                                break;
                            } catch (error) {
                                console.warn("Não foi possível carregar o assistente...");
                            }
                        case 403:
                            setModalState({ showResult: true, titleResult: "Assistente inacessível", result: "Não foi possível conectar com a assistente.", typeResult: "error", buttonDoSomethingText: "Voltar ao início", buttonDoSomethingOnClick: handleBackToStart });
                            break;
                        case 404:
                            setModalState({ showResult: true, titleResult: "Assistente não encontrada", result: `${assistantId} não foi encontrada. Verifique se o link foi digitado corretamente.`, typeResult: "error", buttonDoSomethingText: "Voltar ao início", buttonDoSomethingOnClick: handleBackToStart });
                            break;
                        default:
                            setModalState({ showResult: true, titleResult: "Erro ao carregar assistente", result: "Não foi possível carregar a assistente, entre em contato com o suporte técnico.", typeResult: "error" });
                            break;
                    }
                }
                else {
                    setModalState({ showResult: true, titleResult: "Assistente não encontrada", result: "A assistente não foi encontrada ou você não possui acesso.", typeResult: "error" });
                }
            }
            Promise.resolve(fetchAssistant());
            chatStart();
        }
        catch (e) {
            setModalState({ showResult: true, titleResult: "Erro ao carregar assistente", result: "Não foi possível assistente, entre em contato com o suporte técnico.", typeResult: "error" });
        }
    }, [assistantId]);
    return (
        <ContentChat>
            {messages?.length === 0 && !isLoadingAssistant ?
                <Welcome>
                    <img src={isDarkTheme === "dark" ? lisDark : lis} alt="Logo personagem Lis" />
                    <div>
                        <p>Olá, seja bem-vindo. {assistant ? `Sou ${assistant.id}, assistente digital da Libbs. Como posso ajudar?` : `Selecione um assistente para começar.`}</p>
                        <span>{formatDate(new Date())}</span>
                    </div>
                </Welcome>
                :
                <div></div>
            }
            {!isLoadingAssistant ?
                <div>
                    <ChatMessageContainer className={inIframe() ? "containerIframe" : "container"}>
                        {messages.map(answer => (
                            <>
                                {answer.role === "user" ? (
                                    <div className="chatMessageUser" tabIndex={0}>
                                        <div className="chatMessageUserMessage">{answer.content}</div>
                                    </div>

                                ) : (
                                    answer.role === "assistant" ? <div className="chatMessageGpt">
                                        <Answer
                                            answer={{
                                                id: answer.id,
                                                answer: answer.content,
                                                citations: parseCitationFromMessage(answer),
                                            }}
                                            onCitationClicked={c => onShowCitation(c)}
                                            user_feedback={answer.user_feedback}
                                            message_feedback_handler={message_feedback_handler}
                                            isLoading={isLoading}
                                        />
                                    </div> : answer.role === "error" ? <div className="chatMessageError">
                                        <Stack horizontal className="chatMessageErrorContent">
                                            <ErrorCircleRegular style={{ color: "rgba(182, 52, 67, 1)" }} />
                                            <span>Erro</span>
                                        </Stack>
                                        <span className="chatMessageErrorContent">{answer.content}</span>
                                    </div> : null
                                )}
                            </>
                        ))}
                        {showLoadingMessage && (
                            <>
                                <div className="chatMessageGpt">
                                    <Answer
                                        answer={{
                                            answer: "Gerando resposta...",
                                            citations: []
                                        }}
                                        onCitationClicked={() => null}
                                        message_feedback_handler={message_feedback_handler}
                                    />
                                </div>
                            </>
                        )}
                        {isLoading && (
                            <Stack
                                horizontal
                                className="stopGeneratingContainer"
                                role="button"
                                aria-label="Stop generating"
                                tabIndex={0}
                                onClick={stopGenerating}
                                onKeyDown={e => e.key === "Enter" || e.key === " " ? stopGenerating() : null}
                            >
                                <SquareRegular className="stopGeneratingIcon" aria-hidden="true" />
                                <span className="stopGeneratingText" aria-hidden="true">Cancelar pergunta</span>
                            </Stack>
                        )}
                        <div ref={chatMessageStreamEnd} />
                        {messages && messages.length > 0 && activeCitation && (
                            <Modal
                                isOpen={isModalOpenQuotes}
                                onDismiss={toggleModalQuotes}
                                styles={{
                                    main: {
                                        width: "530px",
                                        maxHeight: "680px",
                                        padding: "30px",
                                        background: isDarkTheme === "dark" ? darkTheme.colors.backgroundConfig : lightTheme.colors.backgroundConfig,
                                        border: `1px solid ${isDarkTheme === "dark" ? darkTheme.colors.border : lightTheme.colors.border}`,
                                        borderRadius: "20px"
                                    },
                                    scrollableContent: {
                                        overflowY: "none"
                                    }
                                }}
                            >
                                <InfoModal>
                                    <h3>Citações</h3>
                                    <h4 className="citationPanelTitle" tabIndex={0}>{activeCitation[2]}</h4>
                                    {activeCitation[4] && activeCitation[4] !== "" && (
                                        <p>Acesse o documento original
                                            <a href={activeCitation[4]} target="_blank">aqui</a>
                                        </p>
                                    )}
                                    <div className="ReactMarkdown">
                                        <ReactMarkdown
                                            linkTarget="_blank"
                                            className="citationPanelContent"
                                            children={activeCitation[0]}
                                            remarkPlugins={[remarkGfm]}
                                            rehypePlugins={[rehypeRaw]}
                                        />
                                    </div>
                                    <div>
                                        <button onClick={toggleModalQuotes}>Fechar</button>
                                    </div>
                                </InfoModal>
                            </Modal>
                        )}
                    </ChatMessageContainer>
                    {(messages.length < 2) && (suggestions.length > 0 || isLoadingSuggestPrompts) &&
                        <QuestionSuggestions>
                            <p>{isLoadingSuggestPrompts ? "Carregando sugestões de pergunta..." : "Sugestões de perguntas:"}</p>
                            <div>
                                {suggestions.map((item, index) => (
                                    <button
                                        key={index}
                                        id={item}
                                        onClick={() => {
                                            sendQuestion(item)
                                        }}
                                    >
                                        {item}
                                    </button>

                                ))}
                            </div>
                        </QuestionSuggestions>
                    }
                    <Question>
                        <div>
                            <textarea
                                placeholder="Digite uma pergunta..."
                                value={question}
                                onChange={(newValue) => onQuestionChange(newValue.target.value)}
                                onKeyDown={onEnterPress}
                                cols={30}
                                rows={5}
                            />
                            <figure>
                                <Icon
                                    onClick={() => sendQuestion()}
                                    iconName="Send"
                                />
                            </figure>
                        </div>
                        <span onClick={toggleModal}>Assuntos conhecidos pela Lis</span>
                        <Modal
                            isOpen={isModalOpen}
                            onDismiss={toggleModal}
                            styles={{
                                main: {
                                    width: "530px",
                                    padding: "30px",
                                    background: isDarkTheme === "dark" ? darkTheme.colors.backgroundConfig : lightTheme.colors.backgroundConfig,
                                    border: `1px solid ${isDarkTheme === "dark" ? darkTheme.colors.border : lightTheme.colors.border}`,
                                    borderRadius: "20px"
                                }
                            }}
                        >
                            <InfoModal>
                                <h2>Assuntos conhecidos pela Lis</h2>
                                <div>
                                    {returnReactMarkdownObject()}
                                </div>
                                <div>
                                    <button onClick={toggleModal}>Fechar</button>
                                </div>
                            </InfoModal>
                        </Modal>
                    </Question>
                </div>
                :
                assistantId ?
                    <ComponentsModal
                        open={modalState.showResult}
                        title={modalState.titleResult}
                        text={modalState.result}
                        modalType={modalState.typeResult}
                        buttonDoSomethingText={modalState.buttonDoSomethingText}
                        doSomethingFunc={modalState.buttonDoSomethingOnClick}
                    />
                    :
                    <div></div>
            }
        </ContentChat>
    )
}