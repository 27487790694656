import styled from "styled-components";

export const ContentContainer = styled.div`
  display: flex;
  height: 100%;
  background-color: ${props => props.theme.colors.borderSection};
  padding: 25px 30px;

  h1, h2, p, span{
    color: ${props => props.theme.colors.color};
    margin: 0;
  }

  i, figure, button{
    cursor: pointer;
  }

  .containerSpinner{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    div div{
      width: 50px;
      height: 50px;
    }
  }
`

export const ContentMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: ${props => props.theme.colors.background};
  border-right: 1px solid ${props => props.theme.colors.borderSection};
  border-radius: 20px 0 0 20px;
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid ${props => props.theme.colors.borderSection};
  padding: 25px 20px;

  h1, i{
    font-size: 30px;
    color: ${props => props.theme.colors.icon};
  }

  i:hover{
    opacity: 0.9;
  }
`

export const NewChat = styled.div`
  display: flex;
  padding: 15px 20px;
  border-bottom: 1px solid ${props => props.theme.colors.borderSection};

  button{
    width: 165px;
    text-align: start;
    background-color: ${props => props.theme.colors.backgroundButton};
    color: ${props => props.theme.colors.colorButton};
    border: none;
    border-radius: 5px;
    padding: 10px 15px;

    &:hover{
      opacity: 0.9;
    }
  }
`

export const YourAssistants = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px 20px;

  ul{
    max-height: 160px;
    overflow-y: auto;
    list-style-type: none;
    padding: 0;
    margin: 0;

    li{
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 8px;
      border-radius: 5px;
      padding: 0;
      margin: 4px 0;
      cursor: pointer;

      > a {
        display: flex;
        text-decoration: none;
        appearance: none;
        border-radius: 8px;
        padding: 8px 20px;
        border-left: 4px solid transparent;
      }

      > a:hover,
      > a.active {
        border-left-color: #23C12E;
      }

      a {

        span{
  
        }
  
        p{
          white-space: nowrap;
          width: 130px;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        div{
          display: flex;

          img{
            fill: red;
            width: 15px;
            height: 15px;
            cursor: pointer;
          }
        }
      }
      


      &:hover{
        background: ${props => props.theme.colors.backgroundConfig}
      }
    }
  }

  li > a:last-child{

    &:hover{
      border-color: ${props => props.theme.colors.borderSection};
    }
  }

  input{
    font-size: 13px;
    color: ${props => props.theme.colors.color};
  }

  .ms-SearchBox::after {
    content: none;
  }
`

export const TooltipHelp = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  background: ${props => props.theme.colors.background};
  padding: 15px 25px 25px;
      
  p{
    font-size: 18px;
    font-weight: bold;
    color: ${props => props.theme.colors.icon};
    margin: 0;
  }

  span{
    font-size: 12px;
    color: ${props => props.theme.colors.color};
    margin: 0;
  }
`

export const Config = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-top: 1px solid ${props => props.theme.colors.borderSection};
  padding: 15px 20px 20px;

  p{
    padding: 10px 0 10px 8px;
  }
  
  > div{
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-radius: 5px;
    cursor: pointer;

    div:last-child{
      display: flex;
      align-items: center;
      padding-right: 10px;
    }
    
    img{
      cursor: pointer;
    }

    &:hover{
      background: ${props => props.theme.colors.backgroundConfig}
    }
  }
  
  .isConfig{
    background: ${props => props.theme.colors.backgroundConfig};

    p{
      font-weight: bold;
      color: ${props => props.theme.colors.icon};
    }

    > div:first-child{
      display: flex;

      span{
        display: block;
        width: 4px;
        height: 100%;
        background: ${props => props.theme.colors.icon};
        border-radius: 100px 0 0 100px;
      }
    }

  }
  
  #isModalOpenLis{
    justify-content: center;

    div{
      padding: 0;

      span{
        display: none;
      }
    }
  }
`

export const ModalConfig = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  > div{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;
  }

  div, p{
    margin: 0;
  }

  > p {
    padding: 5px 0;
    cursor: pointer;
  }

  .ms-Toggle:hover {
    div{
      button{
        background-color: ${props => props.theme.colors.backgroundTogglegHover};;
      }
    }
  }
`

export const InfoModal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  h1, h2, h3, h4, p, span{
    margin: 0;
  }

  i, figure, button{
    cursor: pointer;
  }

  h2{
    color: ${props => props.theme.colors.icon};
  }

  h3, h4{
    color: ${props => props.theme.colors.color};
  }

  a{
    color: ${props => props.theme.colors.icon};

    &:hover{
      opacity: 0.9;
    }
  }

  p, li{
    color: ${props => props.theme.colors.color};
  }

  > div{
    display: flex;
    flex-direction: column;
    gap: 20px;

    ul{
      list-style-type: decimal;
      padding-left: 15px;
      margin: 0;
    }
  }

  > div:last-child{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    button{
      background: ${props => props.theme.colors.background};
      color: ${props => props.theme.colors.color};
      border: 1px solid ${props => props.theme.colors.borderSection};
      border-radius: 10px;
      padding: 10px 30px;

      &:focus-visible{
        outline: none;
      }

      &:hover{
        opacity: 0.9;
      }
    }
  }

  .ReactMarkdown{
    max-height: 380px;
    overflow-y: auto;
  }

  .modalTerm{
    display: flex;
    flex-direction: column;
    gap: 15px;
    height: 500px;
    overflow-y: auto;

    li{
      font-weight: bold;

      ul li, p{
        font-weight: 400;
      }

      ul{
        list-style-type: disc;
      }
    }
  }
`