import { Stack } from '@fluentui/react';
import styles from './RoleForm.module.css';
import {
    Field,
    Input,
    makeResetStyles,
    tokens,
    useId,
    makeStyles,
    InputProps,
    shorthands,
    typographyStyles,
} from "@fluentui/react-components";
import { useLocation, useNavigate } from "react-router-dom";
import LisLogo from "../../../assets/LisLogo-Verde.png";
import { useState } from 'react';
import { ResultModalProps, Role, saveRolesApi} from '../../../api';
import { LoadingButton } from '../../../components/LoadingButton/LoadingButton';
import { SimpleButton } from '../../../components/Button/Button';
import { getAccessToken } from '../../../authConfig';
import { useMsal } from '@azure/msal-react';
import { Modal } from '../../../components/modal/Modal';
import LisLogotip from '../../../components/Logo/LisLogo';

const useStyles = makeStyles({
    wrapper: {
        display: "flex",
        alignItems: "center",
    },
    root: {
        // Stack the label above the field with a gap
        display: "grid",
        gridTemplateRows: "repeat(1fr)",
        justifyItems: "start",
        ...shorthands.gap("2px"),
        maxWidth: "400px",
    },
    description: {
        ...typographyStyles.caption1,
    },
});

export const RoleForm = (props: any) => {
    const { instance } = useMsal();
    const navigation = useNavigate();
    const location = useLocation();
    //trocar pra model e nos campos eu preciso trocar para model  
    const item: Role = location.state?.item || {};

    
    //Assistant Profile
    const [RoleFormHost, setRole] = useState(item.role || null);
    const onRoleFormHostChange: InputProps["onChange"] = (ev, data) => {
        // The controlled input pattern can be used for other purposes besides validation,
        // but validation is a useful example
        if (data.value.length <= 40) {
            setRole(data.value);
        }
    };
                       
    const navigateBack = () => {
        if (history.length > 2) {
            navigation(-1)
        } else {
            navigation('/admin');
        }
    }



    const [isCancelDialogVisible, setIsCancelDialogVisible] = useState<boolean>(false);
    const [saveResult, setSaveResult] = useState<ResultModalProps>({ showResult: false, titleResult: '', result: '', typeResult: undefined });

    //alterar para model deixar estatico azure e openai
    const handleModelButton = async (): Promise<"error" | "loaded"> => {
        if (
            RoleFormHost === null || RoleFormHost === ""
        ){
            console.log("erro na validação dos campos")
            setSaveResult({ showResult: false, titleResult: 'Campos obrigatorios', result: 'Preencha todos os campos obrigatórios para salvar a Role.', typeResult: "error" });
            return "error";
        }
        //alterar para model e apagar para os campos que eu for utilizar
        const role: Partial<Role> = {
            id: item.id,
            role: RoleFormHost,
        }

        let method: "PUT" | "POST";
        if (location.state?.item) {
            method = "PUT";
        }
        else {
            method = "POST";
        }

        const response = await saveRolesApi(await getAccessToken(instance), role,method);
        if (response.status >= 200 && response.status < 300) {
            console.log('Salvo com sucesso')
            return "loaded";
        }
        else {
            console.log('Erro ao salvar')
            const body = await response.json();
            setSaveResult({ showResult: false, titleResult: 'Erro ao salvar Role', result: body.description || 'Ocorreu um erro inesperado ao salvar a Role', typeResult: "error" });
            return "error";
        }
    }

    const handleSuccessSaveAssistant = () => {
        setTimeout(() => {
            setSaveResult({ showResult: false, titleResult: '', result: '', typeResult: undefined });
            navigateBack();
        }, 3500);
    }

    const handleErrorSaveAssistant = () => {
        setSaveResult(prevSaveResult => ({
            ...prevSaveResult,
            showResult: true
        }));
    }

    const showCancelDialog = () => {
        setIsCancelDialogVisible(true);
    };

    const closeCancelDialog = () => {
        setIsCancelDialogVisible(false);
    };

    const closeErrorDialog = () => {
        setSaveResult({ showResult: false, titleResult: '', result: '', typeResult: undefined });
    }

    return (
        <>
            <Stack horizontal className={styles.adminRoot}>
                <div className={styles.adminContainer}>
                    <div className={styles.adminHeader}>
                        <LisLogotip className={styles.LisIcon} aria-hidden="true" />
                    </div>
                    <div className={styles.formContainer}>
                        <div className={styles.fieldsContainer}>
                            <div className={styles.formColumn}>
                                <Field label="Nome da Role" required>
                                    <Input value={RoleFormHost|| ""} onChange={onRoleFormHostChange} />
                                </Field>
                            </div>

                        </div>
                        <div className={styles.buttonsContainer}>
                            <SimpleButton onClickFunction={showCancelDialog} buttonText="Cancelar" backgroundColor="#878787" textColor="white" />
                            <LoadingButton onClickFunction={handleModelButton} initialTextButton="Salvar" loadingTextButton="Salvando" loadedTextButton="Salvo" onSuccessAction={handleSuccessSaveAssistant} onErrorAction={handleErrorSaveAssistant} />
                        </div>
                    </div>
                </div>
            </Stack>

            <Modal
                open={isCancelDialogVisible}
                closeModalFunc={closeCancelDialog}
                doSomethingFunc={navigateBack}
                title="Cancelar"
                text="Tem certeza de que deseja cancelar?"
                buttonDoSomethingText="Cancelar"
                buttonCloseText="Permanecer"
                modalType="info"
            />
            <Modal
                open={saveResult.showResult}
                title={saveResult.titleResult}
                text={saveResult.result}
                modalType={saveResult.typeResult}
                buttonCloseText="Fechar"
                closeModalFunc={closeErrorDialog}
            />
        </>

    )

};