import { Stack } from "@fluentui/react"
import styles from './ChatEmptyState.module.css'
import { useMsal } from "@azure/msal-react"
import { getAccessToken, getToken } from "../../authConfig"
import { useEffect, useState } from "react"
import { suggestPromptsApi, SuggestPrompts, assistantSuggestPromptsApi } from "../../api"
import {
    Skeleton,
    SkeletonItem,
    SkeletonProps,
} from "@fluentui/react-components";
import { useNavigate } from "react-router-dom"
import { handleGetTokenError } from "../../utils/auth"

interface ChatEmptyStateProps {
    chatText?: string | undefined
    setQuestion: (_question: string) => void
    index?: string,
    assistantId?: string
}

const promptSuggestionsTemplate = [
    'Quais as regras da PPR?',
    'Como faço para solicitar férias?',
    'Como faço para solicitar um adiantamento?',
    'Qual o valor do vale-refeição?',
    'Como faço para solicitar um reembolso?'
]

export const ChatEmptyState = (props: ChatEmptyStateProps) => {
    const { instance, accounts } = useMsal();
    const [suggestions, setSuggestions] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const navigate = useNavigate();

    const handleOnClickSuggestions = (suggestion: string) => {
        props.setQuestion(suggestion);
    }

    useEffect(() => {
        const fetchData = async () => {
            let response
            const token = await getAccessToken(instance);

            if (!token) {
                handleGetTokenError(navigate)
            }

            if(props.assistantId && !props.index){
                response = await assistantSuggestPromptsApi(token, props.assistantId);
            }

            if(!props.assistantId && props.index){
                response = await suggestPromptsApi(token, props.index);
            }
            
            if (response?.body) {
                const result : SuggestPrompts = await response.json() ;
                setSuggestions(result.promptSuggestions);
            }
            else {
                setSuggestions([]);
            }
            setIsLoading(false);
        };
        fetchData();
    }, [])


    return (
        <Stack className={styles.chatEmptyState}>
            <Stack className={styles.chatEmptyStateWelcomeContainer}>
                <h1 className={styles.chatEmptyStateTitle}>Comece a conversar</h1>
                {
                    props.chatText && (
                        <h2 className={styles.chatEmptyStateSubtitle}>{props.chatText}</h2>
                    )
                }
            </Stack>
            {(isLoading && ((props.index !== null && props.index !== undefined) || (props.assistantId))) && (
                <Stack className={styles.chatEmptyStateSuggestionContainer}>
                    <Stack className={styles.chatEmptyStateSuggestionGroup}>
                        {
                            promptSuggestionsTemplate.map((suggestion, index) => (
                                <Skeleton key={index}  className={styles.chatEmptyStateSuggestionCardsSkeleton} appearance="opaque" animation="wave">
                                    <SkeletonItem className={styles.skeleton} />
                                </Skeleton>
                            ))
                        }
                    </Stack>
                </Stack>
            )
            }
            {
                (suggestions.length > 0 && ((props.index !== null && props.index !== undefined) || (props.assistantId))) && (
                    <Stack className={styles.chatEmptyStateSuggestionContainer}>
                        <Stack className={styles.chatEmptyStateSuggestionGroup}>
                            {
                                suggestions.map((suggestion, index) => (
                                    <Stack key={index} className={styles.chatEmptyStateSuggestionCards} onClick={() => handleOnClickSuggestions(suggestion)}><p>{suggestion}</p></Stack>
                                ))
                            }
                        </Stack>
                    </Stack>
                )
            }

        </Stack>
    )
}