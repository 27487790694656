import {
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
  Button,
  DialogOpenChangeData,
} from "@fluentui/react-components";
import styles from './UseTerms.module.css';
import { Info24Regular } from '@fluentui/react-icons';
import { SyntheticEvent, useCallback, useEffect, useRef, useState } from "react";

interface ModalProps {
  modalType?: string;
  color?: string;
  buttonAcceptText?: string;
  buttonDoSomethingText?: string;
  doSomethingFunc?: () => void;
}

export const UseTerms = (props: ModalProps) => {
  const [open, setOpen] = useState(false);
  const divUseTerms = useRef(null);

  const handleDoSomethinModalFunc = () => {
    if (props.doSomethingFunc) {
      props.doSomethingFunc();
    }
  }

  const handleAcceptTerms = () => {
    try {
      const acceptedTerms = {
        accepted: true,
        acceptedIn: new Date()
      }
      localStorage.setItem('acceptedTerms', JSON.stringify(acceptedTerms));
    } catch (error) {
      console.error('Erro ao salvar a seleção do usuário:', error);
    }
  }

  const getPreviousSelectedIndex = () => {
    try {
      const acceptedTerms = localStorage.getItem('acceptedTerms')
      //Caso acceptedTerms não possua valor
      if (!acceptedTerms || !JSON.parse(acceptedTerms).accepted) {
        setOpen(true);
      }
    } catch (error) {
      console.error('Erro ao recuperar aceite do termos de uso:', error);
    }
  }

  useEffect(() => {
    getPreviousSelectedIndex();
  }, []);

  const handleEvent = (event: React.KeyboardEvent<HTMLElement> | React.MouseEvent<HTMLElement, MouseEvent>, data: DialogOpenChangeData) => {
    //@ts-ignore
    if (event.key === "Escape") {
      event.preventDefault();
      return
    }
    setOpen(data.open);
  }

  return (
    <div className={styles.dialogDiv} >
      <Dialog open={open} onOpenChange={(event, data) => { handleEvent(event, data) }} modalType="alert">
        <DialogSurface className={styles.surfaceModal}>
          <DialogBody className={styles.bodyModal}>
            <DialogTitle>Termos de uso e de responsabilidade - LIS</DialogTitle>
            <DialogContent className={styles.contentModal}>

              <p>
                Bem-vindo(a) à nossa assistente virtual com inteligência artificial. Antes de utilizar nossos serviços, pedimos que leia atentamente os termos abaixo, pois eles estabelecem as diretrizes para o uso responsável e ético desta ferramenta. Ao acessar e utilizar a assistente virtual, você concorda com os termos descritos a seguir:
              </p>
              <p>
                1. Responsabilidade do Usuário:
              </p>
              <p>
                O usuário é integralmente responsável pelo uso da assistente virtual.
                Deve garantir que todas as interações estejam em conformidade com leis locais, nacionais e internacionais.
                É proibido o uso da assistente para atividades ilegais, fraudulentas, difamatórias, ou que infrinjam os direitos de terceiros.
              </p>
              <p>
                2. Uso Ético:
              </p>
              <p>
                Compromete-se a utilizar a assistente de maneira ética e respeitosa.
                Evite interações que possam causar danos emocionais, ofensas, discriminação ou prejuízos a outros usuários.
              </p>
              <p>
                3. Privacidade e Dados Pessoais:
              </p>
              <p>
                Respeitamos sua privacidade. As informações coletadas serão utilizadas estritamente para melhorar a experiência do usuário e não serão compartilhadas com terceiros sem o seu consentimento.
              </p>
              <p>
                4. Segurança:
              </p>
              <p>
                O usuário é responsável por manter suas credenciais seguras e notificar-nos imediatamente sobre qualquer acesso não autorizado à sua conta.
              </p>
              <p>
                5. Atualizações e Melhorias:
              </p>
              <p>
                Reservamo-nos o direito de realizar atualizações na assistente virtual para melhorar sua eficiência e segurança.
              </p>
              <p>
                6. Limitações da Assistente Virtual:
              </p>
              <p>
                A assistente pode não ser infalível. Não garantimos a precisão ou adequação de todas as respostas ou informações fornecidas, revise sempre as respostas para garantir que são válidas.
              </p>
              <p>
                7. Contato:
              </p>
              <p>
                Ao utilizar a assistente virtual, você concorda e compromete-se a respeitar estes termos de uso. Reservamo-nos o direito de modificar estes termos a qualquer momento, e é responsabilidade do usuário revisá-los periodicamente. O não cumprimento destes termos pode resultar na suspensão ou encerramento do acesso à assistente virtual e demais medidas pertinentes de acordo com as normas da empresa.
              </p>
              <p>
                Agradecemos por escolher nossa assistente virtual. Esperamos que tenha uma experiência positiva e produtiva!
              </p>
            </DialogContent>
            <DialogActions>
              <DialogTrigger disableButtonEnhancement>
                <Button appearance="secondary" onClick={() => handleAcceptTerms()} >{props.buttonAcceptText ? props.buttonAcceptText : "OK"}</Button>
              </DialogTrigger>
              {
                props.buttonDoSomethingText && (
                  <Button appearance="primary" onClick={() => handleDoSomethinModalFunc()}>{props.buttonDoSomethingText}</Button>
                )
              }
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    </div>
  );
}