import { useEffect, useMemo, useState } from "react";
import { useBoolean } from "@fluentui/react-hooks"
import { FontIcon, Stack, Text } from "@fluentui/react";

import styles from "./Answer.module.css";

import { AskResponse, Citation } from "../../api";
import { parseAnswer } from "./AnswerParser";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import supersub from 'remark-supersub'
import LisPersona from "../../assets/lis-persona.png"
import { ThumbLikeRegular, ThumbDislikeRegular, ThumbDislikeFilled, ThumbLikeFilled } from "@fluentui/react-icons";

interface Props {
    answer: AskResponse;
    onCitationClicked: (citedDocument: Citation) => void;
    user_feedback?: boolean | null;
    message_feedback_handler: (message_id: string | undefined, feedback: boolean) => void;
    isLoading?: boolean;
}

export const Answer = ({
    answer,
    onCitationClicked,
    user_feedback,
    message_feedback_handler,
    isLoading
}: Props) => {
    const [isRefAccordionOpen, { toggle: toggleIsRefAccordionOpen }] = useBoolean(false);
    const [localUserFeedback, setLocalUserFeedback] = useState(user_feedback);
    const filePathTruncationLimit = 50;

    const parsedAnswer = useMemo(() => parseAnswer(answer), [answer]);
    const [chevronIsExpanded, setChevronIsExpanded] = useState(isRefAccordionOpen);

    const handleChevronClick = () => {
        setChevronIsExpanded(!chevronIsExpanded);
        toggleIsRefAccordionOpen();
    };

    useEffect(() => {
        setChevronIsExpanded(isRefAccordionOpen);
    }, [isRefAccordionOpen]);

    const createCitationFilepath = (citation: Citation, index: number, truncate: boolean = false) => {
        let citationFilename = "";

        if (citation.filename && citation.chunk_id) {
            if (truncate && citation.filename.length > filePathTruncationLimit) {
                const citationLength = citation.filename.length;
                citationFilename = `${citation.filename.substring(0, 20)}...${citation.filename.substring(citationLength - 20)} - Parte ${parseInt(citation.chunk_id) + 1}`;
            }
            else {
                citationFilename = `${citation.filename} - Parte ${parseInt(citation.chunk_id) + 1}`;
            }
        }
        else if (citation.filename && citation.reindex_id) {
            citationFilename = `${citation.filename} - Parte ${citation.reindex_id}`;
        }
        else {
            citationFilename = `Citação ${index}`;
        }
        return citationFilename;
    }

    const handleFeedbackClick = (feedback: boolean) => {
        setLocalUserFeedback(feedback)
        message_feedback_handler(answer.id, feedback)
    }

    return (
        <>
            <Stack className={`${styles.answerIconContainer}`}>
                <img className={styles.answerIcon} src={LisPersona} alt="Lis Persona" />
            </Stack>
            <Stack className="answerContainer" tabIndex={0}>
                <Stack.Item grow className={styles.answerItemContainer}>
                    <ReactMarkdown
                        linkTarget="_blank"
                        remarkPlugins={[remarkGfm, supersub]}
                        children={parsedAnswer.markdownFormatText}
                        className="answerText"
                    />
                </Stack.Item>
                <Stack horizontal className={styles.answerFooter}>
                    {!!parsedAnswer.citations.length && (
                        <Stack.Item
                            onKeyDown={e => e.key === "Enter" || e.key === " " ? toggleIsRefAccordionOpen() : null}
                        >
                            <Stack style={{ width: "100%" }} >
                                <Stack horizontal horizontalAlign='start' verticalAlign='center'>
                                    <Text
                                        className={styles.accordionTitle}
                                        onClick={toggleIsRefAccordionOpen}
                                        aria-label="Open references"
                                        tabIndex={0}
                                        role="button"
                                    >
                                        <span>{parsedAnswer.citations.length > 1 ? parsedAnswer.citations.length + " referências" : "1 referência"}</span>
                                    </Text>
                                    <FontIcon className={styles.accordionIcon}
                                        onClick={handleChevronClick} iconName={chevronIsExpanded ? 'ChevronDown' : 'ChevronRight'}
                                    />
                                </Stack>

                            </Stack>
                        </Stack.Item>
                    )}
                    <Stack.Item className={styles.answerDisclaimerContainer}>
                        <span className={styles.answerDisclaimer}>Conteúdo gerado por IA, necessita ser revisado. <a className="reportFormLink" href="https://forms.office.com/r/T5jQdDUStk" target="_blank">Reporte problemas ou sugestões!</a></span>
                        {
                            (localUserFeedback !== undefined && !isLoading) && (
                                <Stack.Item className={styles.answerFeedbackContainer}>
                                    <button type="button" className={styles.answerFeedbackButton} onClick={() => handleFeedbackClick(true)}>
                                        {localUserFeedback == true ? <ThumbLikeFilled color="gray" /> : <ThumbLikeRegular color="gray" />}
                                    </button>
                                    <button type="button" className={styles.answerFeedbackButton} onClick={() => handleFeedbackClick(false)}>
                                        {localUserFeedback == false ? <ThumbDislikeFilled color="gray" /> : <ThumbDislikeRegular color="gray" />}
                                    </button>
                                </Stack.Item>
                            )
                        }
                    </Stack.Item>
                </Stack>
                {chevronIsExpanded &&
                    <div className="referenceList">
                        {parsedAnswer.citations.map((citation, idx) => {
                            return (
                                <span
                                    title={createCitationFilepath(citation, ++idx)}
                                    tabIndex={0}
                                    role="link"
                                    key={idx}
                                    onClick={() => onCitationClicked(citation)}
                                    onKeyDown={e => e.key === "Enter" || e.key === " " ? onCitationClicked(citation) : null}
                                    className={styles.citationContainer}
                                    aria-label={createCitationFilepath(citation, idx)}
                                >
                                    <div className={styles.citation}>{idx}</div>
                                    {createCitationFilepath(citation, idx, true)}
                                </span>);
                        })}
                    </div>
                }
            </Stack>
        </>
    );
};
