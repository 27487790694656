import { useMsal } from '@azure/msal-react';
import { Stack, Text, PrimaryButton } from '@fluentui/react';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AbilityContext } from '../api/security/contextualPermission';

const Error404 = () => {
    const navigate = useNavigate();

    return <>
        <Text variant="mega" as="p">
            404 - Página não encontrada
        </Text>
        <Text variant="large" as="p">
            Desculpe, mas a página que você está procurando não existe.
        </Text>
        <PrimaryButton
            text="Voltar para a página inicial"
            style={{ borderRadius: '7px', backgroundColor: '#517D7F' }}
            onClick={() => {
                navigate('/');
            }}
        />
    </>;
}

const Error403 = () => {
    return <>
        <Text variant="superLarge" as="p">
            403 - Você não tem permissão para acessar esta página
        </Text>
        <Text variant="large" as="p">
            Desculpe, mas você não tem acesso à página que você está procurando. Se acredita que isso seja um engano, por favor, contate o administrador.
        </Text>
    </>
}

const NoPage = () => {
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const ability = useContext(AbilityContext);
    const isNotAuthorized = activeAccount !== null && ability.cannot('chat', 'Assistant');

    return (
        <Stack horizontalAlign="center" verticalAlign="center" styles={{ root: { height: '100vh' } }} >
            {isNotAuthorized ? <Error403 /> : <Error404 />}
        </Stack>
    );
};

export default NoPage;