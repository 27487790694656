import { Input, InputOnChangeData } from "@fluentui/react-components";
import { SearchFilled } from "@fluentui/react-icons";
import styles from './SearchField.module.css';

interface SearchFieldProps {
    id: string;
    value: string | null | undefined;
    onChange: ((ev: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => void) | undefined;
}

export const SearchField = (props : SearchFieldProps) => {

    
    return (
        <div className={styles.searchFieldContainer}>
            <Input contentBefore={<SearchFilled />} id={props.id} className={styles.searchField} value={props.value || ""} onChange={props.onChange} />
        </div>
    )
}