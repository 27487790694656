
import * as React from "react";
import {
  buttonClassNames,
  makeStyles,
  shorthands,
  tokens,
  Button,
  Spinner,
} from "@fluentui/react-components";
import { CheckmarkFilled, DismissFilled } from "@fluentui/react-icons";

const useStyles = makeStyles({
  wrapper: {
    columnGap: "15px",
    display: "flex",
  },
  buttonNonInteractive: {
    ...shorthands.border(
      tokens.strokeWidthThin,
      "solid",
      tokens.colorNeutralStroke1
    ),
  },
});


interface ButtonProps {
    onClickFunction: () => void | Promise<void>;
    buttonText: string;
    backgroundColor?: string;
    textColor?: string;
}

export const SimpleButton = (props : ButtonProps) => {
  const styles = useStyles();

  const onButtonClick = async () => {
    try {
      await props.onClickFunction();
    } catch (error) {
        console.log(error)
    }
  };

  const buttonClassName = styles.buttonNonInteractive;

  return (
    <div className={styles.wrapper}>
      <Button
        className={buttonClassName}
        color={props.backgroundColor || "gray"}
        style={
            { 
                backgroundColor: props.backgroundColor || "#878787",
                color: props.textColor || "white",
                cursor: "pointer"
            }
        }
        onClick={onButtonClick}
      >
        {props.buttonText}
      </Button>
    </div>
  );
};