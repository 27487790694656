import { Stack } from "@fluentui/react";
import styles from './Roles.module.css';
import { TableComponent } from "../../../components/Table/TableComponent";
import { SimpleButton } from "../../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { ResultModalProps, Role, deleteRoleApi, listRolesApi } from "../../../api";
import { getAccessToken } from "../../../authConfig";
import { Modal } from "../../../components/modal/Modal";

const columns = [
    { columnKey: "id", label: "Id do modelo", attribute: "id", filterable: true },
    { columnKey: "role", label: "Role", attribute: "role", filterable: true },
    { columnKey: "actions", label: "Actions" },
];

const items = [
    {
        id: 1,
        role: "Admin.All"
    },
    {
        id: 2,
        role: "Assistant.InfoMedicas"
    },
    {
        id: 3,
        role: "User"
    }
];

const Roles = () => {
    const navigation = useNavigate();
    const { instance } = useMsal();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [roles, setRoles] = useState<Role[]>([]);
    const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);
    const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState<boolean>(false);
    const [deleteItem, setDeleteItem] = useState<Role | null>(null);
    const [deleteResult, setDeleteResult] = useState<ResultModalProps>({ showResult: false, titleResult: '', result: '', typeResult: undefined });

    useEffect(() => {
        try {
            const fetchData = async () => {
                setIsLoading(true);
                const response = await listRolesApi(await getAccessToken(instance));
                if (response?.body) {
                    console.log('response com body')
                    const result: Role[] = await response.json();
                    console.log(result)
                    setRoles(result);
                }
                else {
                    console.log('response sem body')
                    setRoles([]);
                }
                setIsLoading(false);
            };
            Promise.resolve(fetchData());
        } catch (error) {
            console.log('error', error);
        }
    }, []);

    const createButton = () => {
        navigation('/admin/roles/form');
    }

    const editHanler = (item: any) => {
        navigation('/admin/roles/form', { state: { item } });
    }

    const deleteHandler = async () => {
        if (deleteItem) {
            setIsDeleteLoading(true);
            const response = await deleteRoleApi(await getAccessToken(instance), deleteItem.id);
            setIsDeleteLoading(false);
            setIsDeleteDialogVisible(false);
            if (response.status >= 200 && response.status < 300) {
                setRoles(prevRoles => prevRoles.filter(role => role.id !== deleteItem.id));
                setDeleteResult({
                    showResult: true,
                    titleResult: 'Role excluída',
                    result: 'Role excluída com sucesso.',
                    typeResult: 'info'
                });
            } else {
                setDeleteResult({
                    showResult: true,
                    titleResult: 'Erro',
                    result: 'Ocorreu um erro ao excluir a role',
                    typeResult: 'error'
                });
            }
            // Adiciona um delay antes de alterar showResult para false
            setTimeout(() => {
                setDeleteResult(prevResult => ({
                    ...prevResult,
                    showResult: false
                }));
            }, 2000);
            setDeleteItem(null);
        }
    };

    const showDeleteDialog = (item: Role) => {
        setDeleteItem(item);
        setIsDeleteDialogVisible(true);
    };

    const closeDeleteDialog = () => {
        setIsDeleteDialogVisible(false);
        setDeleteItem(null);
    };

    return (
        <>
            <Stack className={styles.container}>
                <div className={styles.createButton}>
                    <SimpleButton onClickFunction={createButton} buttonText="Criar" backgroundColor="#517D7F" textColor="white" />
                </div>
                <TableComponent key={roles.length} loading={isLoading} columns={columns} items={roles} onEditFunction={editHanler} onDeleteFunction={showDeleteDialog}  />
            </Stack>

            <Modal
                open={isDeleteDialogVisible}
                closeModalFunc={closeDeleteDialog}
                doSomethingFunc={deleteHandler}
                title="Confirmar Exclusão"
                text="Tem certeza de que deseja excluir esta role?"
                buttonDoSomethingText="Excluir"
                buttonCloseText="Cancelar"
                modalType="info"
            />
            <Modal
                open={isDeleteLoading}
                title="Excluindo role"
                text="Excluindo role..."
                modalType="wait"
            />
            <Modal
                open={deleteResult.showResult}
                title={deleteResult.titleResult}
                text={deleteResult.result}
                modalType={deleteResult.typeResult}
            />
        </>
    )
}

export default Roles;