import { ThemeContext, useThemeContext } from "../Context/Theme";
import { Config, Header, YourAssistants, ModalConfig, NewChat, InfoModal, ContentMenu, TooltipHelp } from "../style";
import { useContext, useState } from "react";
import { ICalloutProps, Icon, Modal, SearchBox, TeachingBubble, Toggle, TooltipHost } from "@fluentui/react";
import { useBoolean, useId } from "@fluentui/react-hooks";
import settings from '../assets/settings.png';
import settingsTrue from '../assets/settingsTrue.png';
import helpWhite from '../assets/helpWhite.png';
import helpBlack from '../assets/helpBlack.png';
import { darkTheme, lightTheme } from "../../../styles/theme";
import { AssistantModel } from "../../../api";
import { NavLink, useNavigate } from "react-router-dom";
import debounce from "lodash-es/debounce";
import { ChatContext } from "../Context/Chat";

interface Menutype {
    assistants: AssistantModel[]
}

export default function Menu(props: Menutype) {
    const { assistants } = props
    const [isModalOpenTerm, setIsModalOpenTerm] = useState(false)
    const [isModalOpenHelp, setIsModalOpenHelp] = useState(false)
    const [isModalOpenLis, setIsModalOpenLis] = useState(false)
    const [search, setSearch] = useState("")
    const [isConfig, { toggle: toggleIsConfigVisible }] = useBoolean(false)
    const navigate = useNavigate()
    const { changeTheme } = useThemeContext()
    const { isDarkTheme } = useContext(ThemeContext)
    const buttonConfigModal = useId('ConfigModal')
    const debouncedSearch = debounce(setSearch, 300)
    const { setMessages } = useContext(ChatContext)

    const calloutStyles: ICalloutProps['styles'] = {
        beak: {
            display: "none"
        },
        calloutMain: {
            borderRadius: "15px",
            background: isDarkTheme === "dark" ? darkTheme.colors.backgroundButton : lightTheme.colors.backgroundButton,
            border: `${isDarkTheme === "dark" ? `1px solid ${darkTheme.colors.border}` : 'none'}`,
        },
        beakCurtain: {
            borderRadius: "15px"
        },
        root: {
            maxWidth: "200px",
        },
    };

    const toggleModalLis = () => {
        setIsModalOpenLis(!isModalOpenLis)
    }

    const toggleModalHelp = () => {
        setIsModalOpenHelp(!isModalOpenHelp)
    }

    const toggleModalTerm = () => {
        setIsModalOpenTerm(!isModalOpenTerm)
    }

    return (
        <>
            <ContentMenu>
                <div>
                    <Header >
                        {!isModalOpenLis &&
                            <h1>Lis</h1>
                        }
                        <Icon
                            onClick={toggleModalLis}
                            iconName="GlobalNavButton"
                        />
                    </Header>
                    {!isModalOpenLis &&
                        <NewChat>
                            <button onClick={() => {
                                setMessages([])
                            }}>
                                Novo chat
                            </button>
                        </NewChat>
                    }
                    {!isModalOpenLis &&
                        <YourAssistants>
                            <div>
                                <p>Suas assistentes</p>
                            </div>
                            <ul>
                                {assistants.map(item => {
                                    const tooltip = () => {
                                        return (
                                            <TooltipHelp>
                                                <p>{item.profile}</p>
                                                <span>{item.description}</span>
                                            </TooltipHelp>
                                        )
                                    }
                                    return (
                                        item.profile.toLocaleLowerCase().includes(search.toLocaleLowerCase()) &&
                                        <li
                                            key={item.id}
                                            id={item.id}
                                        >
                                            <NavLink to={`/${item.id}`}>
                                                <p title={item.profile}>{item.profile}</p>
                                            </NavLink>
                                            <div>
                                                <TooltipHost content={tooltip()}
                                                    tooltipProps={{
                                                        calloutProps: {
                                                            styles: {
                                                                beak: { display: "none" },
                                                                beakCurtain: {
                                                                    background: isDarkTheme === "dark" ? darkTheme.colors.background : lightTheme.colors.background,
                                                                    borderRadius: "10px"
                                                                },
                                                                calloutMain: { background: isDarkTheme === "dark" ? darkTheme.colors.background : lightTheme.colors.background },
                                                                root: { borderRadius: "10px", background: "transparent" }
                                                            },
                                                        },
                                                    }}>
                                                    <img src={isDarkTheme === "dark" ? helpWhite : helpBlack} alt="informação" />
                                                </TooltipHost>
                                            </div>
                                        </li>
                                    )
                                })}
                            </ul>
                            <SearchBox
                                className="ms-SearchBox"
                                placeholder="Buscar assistente"
                                onSearch={debouncedSearch}
                                onChange={(event) => debouncedSearch(event?.target.value || "")}
                                autoFocus={false}
                                styles={{
                                    root: {
                                        width: "165px",
                                        backgroundColor: isDarkTheme === "dark" ? darkTheme.colors.background : lightTheme.colors.background,
                                        borderColor: isDarkTheme === "dark" ? darkTheme.colors.borderSection : lightTheme.colors.borderSection,
                                        borderRadius: "100px",
                                        display: "flex",
                                        flexDirection: "row-reverse",
                                        padding: "0 5px 0 10px",
                                    },
                                    icon: {
                                        transform: "scaleX(-1)",
                                        color: isDarkTheme === "dark" ? darkTheme.colors.color : lightTheme.colors.color,
                                        fontWeight: "600",
                                        fontSize: "14px",
                                    },
                                    clearButton: {
                                        display: "none"
                                    }
                                }}
                            />
                        </YourAssistants>
                    }
                </div>
                <Config>
                    <div
                        className={isConfig ? "isConfig" : ""}
                        id={isModalOpenLis ? "isModalOpenLis" : ""}
                        onClick={toggleIsConfigVisible}
                    >
                        {!isModalOpenLis ?
                            <div>
                                {isConfig &&
                                    <span></span>
                                }
                                <p>Configurações</p>
                            </div>
                            :
                            <></>
                        }
                        <div>
                            <img
                                id={buttonConfigModal}

                                src={isConfig ? settingsTrue : settings}
                                alt="Configuração"
                            />
                            {isConfig &&
                                <TeachingBubble
                                    target={`#${buttonConfigModal}`}
                                    onDismiss={toggleIsConfigVisible}
                                    styles={{
                                        root: {
                                            maxWidth: "200px",
                                            borderRadius: "15px"
                                        },
                                        bodyContent: {
                                            background: isDarkTheme === "dark" ? darkTheme.colors.background : lightTheme.colors.background,
                                            padding: "17px"
                                        },
                                        subComponentStyles: {
                                            callout: calloutStyles
                                        },
                                        subText: {
                                            color: isDarkTheme === "dark" ? darkTheme.colors.color : lightTheme.colors.color
                                        },
                                    }}
                                >
                                    <ModalConfig>
                                        <div>
                                            <p>Modo escuro</p>
                                            <Toggle
                                                className="ms-Toggle"
                                                defaultChecked={isDarkTheme === "dark" ? true : false}
                                                onChange={changeTheme}
                                                styles={{
                                                    pill: {
                                                        backgroundColor: isDarkTheme === "dark" ? darkTheme.colors.backgroundToggleg : lightTheme.colors.backgroundToggleg,
                                                        border: "none"
                                                    },
                                                    thumb: {
                                                        backgroundColor: isDarkTheme === "dark" ? darkTheme.colors.colorButton : lightTheme.colors.colorButton
                                                    }
                                                }}
                                            />
                                        </div>
                                        <p onClick={toggleModalTerm}>Termos de Uso</p>
                                        <p onClick={toggleModalHelp}>Ajuda</p>
                                    </ModalConfig>
                                </TeachingBubble>
                            }
                        </div>
                    </div>
                </Config>
            </ContentMenu>
            <Modal
                isOpen={isModalOpenTerm}
                onDismiss={toggleModalTerm}
                styles={{
                    main: {
                        width: "530px",
                        padding: "30px",
                        background: isDarkTheme === "dark" ? darkTheme.colors.backgroundConfig : lightTheme.colors.backgroundConfig,
                        border: `1px solid ${isDarkTheme === "dark" ? darkTheme.colors.border : lightTheme.colors.border}`,
                        borderRadius: "20px"
                    }
                }}
            >
                <InfoModal>
                    <h2>Termos de uso</h2>
                    <div>
                        <ul className="modalTerm">
                            <li>Introdução
                                <p>Ao acessar ou usar nossos serviços, você concorda em cumprir e estar vinculado aos seguintes termos e condições.</p>
                            </li>
                            <li>Definições
                                <ul>
                                    <li>Usuário: Qualquer pessoa que acesse ou utilize os serviços.</li>
                                    <li>Serviços: Todos os produtos, serviços, conteúdo e funcionalidades oferecidos pela  [Nome do Site/App].</li>
                                </ul>
                            </li>
                            <li>Uso dos Serviços
                                <p>Você concorda em usar os serviços apenas para fins legais e de acordo com estes termos. É proibido:
                                </p>
                                <ul>
                                    <li>Violar qualquer lei ou regulamento aplicável.</li>
                                    <li>Usar os serviços para qualquer propósito fraudulento ou malicioso.</li>
                                </ul>
                            </li>
                            <li>Conta de Usuário
                                <p>Para acessar certos recursos, você pode precisar criar uma conta. Você é responsável por manter a confidencialidade de suas informações de login e por todas as atividades que ocorram sob sua conta.</p>
                            </li>
                            <li>Propriedade Intelectual
                                <p>Todo o conteúdo, incluindo textos, gráficos, logotipos, ícones, imagens, clipes de áudio e software, é propriedade do [Nome do Site/App] ou de seus licenciadores e está protegido por leis de direitos autorais e outras leis de propriedade intelectual.</p>
                            </li>
                            <li>Limitação de Responsabilidade
                                <p>O [Nome do Site/App] não será responsável por quaisquer danos indiretos, incidentais, especiais, consequenciais ou punitivos, incluindo perda de lucros, dados, uso, boa vontade ou outras perdas intangíveis, resultantes de:</p>
                                <ul>
                                    <li>Seu uso ou incapacidade de usar os serviços.</li>
                                    <li>Qualquer acesso não autorizado ou uso de nossos servidores e/ou qualquer informação pessoal armazenada neles.</li>
                                </ul>
                            </li>
                            <li>Modificações dos Termos
                                <p>Reservamo-nos o direito de modificar estes termos a qualquer momento. Quaisquer alterações serão publicadas nesta página e, se as alterações forem significativas, forneceremos um aviso mais destacado.</p>
                            </li>
                            <li>Contato
                                <p>Se você tiver alguma dúvida sobre estes termos, entre em contato conosco em [email de contato].</p>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <button onClick={toggleModalTerm}>Fechar</button>
                    </div>
                </InfoModal>
            </Modal>
            <Modal
                isOpen={isModalOpenHelp}
                onDismiss={toggleModalHelp}
                styles={{
                    main: {
                        width: "530px",
                        padding: "30px",
                        background: isDarkTheme === "dark" ? darkTheme.colors.backgroundConfig : lightTheme.colors.backgroundConfig,
                        border: `1px solid ${isDarkTheme === "dark" ? darkTheme.colors.border : lightTheme.colors.border}`,
                        borderRadius: "20px"
                    }
                }}
            >
                <InfoModal>
                    <h2>Ajuda</h2>
                    <div>
                        <h3>Se você está enfrentando problemas técnicos e precisa de suporte, entre em contato com o time de TI seguindo as instruções abaixo:</h3>
                        <ul>
                            <ol>Envie um e-mail para <a href="mailto:ti.suporte@libbs.com.br">ti.suporte@libbs.com.br</a>.</ol>
                            <ol><span>Inclua os seguintes detalhes no e-mail</span>:
                                <ul>
                                    <li><strong>Descrição detalhada do problema</strong>: Explique claramente a dificuldade que está enfrentando.</li>
                                    <li><strong>Passos realizados antes do problema ocorrer</strong>: Detalhe as ações que você realizou antes de encontrar o problema.</li>
                                    <li><strong>Mensagens de erro</strong>: Se houver mensagens de erro, inclua-as no e-mail.</li>
                                    <li><strong>Capturas de tela</strong>: Se possível, anexe capturas de tela que ilustrem o problema.</li>
                                    <li><strong>Informações do dispositivo</strong>: Informe o modelo do seu computador e o sistema operacional que está utilizando.</li>
                                    <li><strong>Urgência</strong>: Indique se o problema é urgente e está impedindo o seu trabalho.</li>
                                </ul>
                            </ol>
                        </ul>
                        <p>Seguindo essas orientações, a equipe de TI poderá entender melhor a sua situação e fornecer um suporte mais eficiente.</p>
                        <p>Para mais informações ou dúvidas, entre em contato com a equipe de TI pelo e-mail mencionado acima.</p>
                    </div>
                    <div>
                        <button onClick={toggleModalHelp}>Fechar</button>
                    </div>
                </InfoModal>
            </Modal>
        </>
    )
}