import { LockClosed12Filled } from "@fluentui/react-icons";
import styles from "./Login.module.css";
import LisLogo from '../../assets//LisLogo-Verde.png';
import { InteractionStatus, IPublicClientApplication } from "@azure/msal-browser";
import { useEffect, useRef, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { login } from "../../authConfig";
import { Modal } from "../../components/modal/Modal";
import EnablePopUps from '../../assets/enable-pop-up.png';
import * as microsoftTeams from '@microsoft/teams-js';
import { useIsAuthenticated } from "@azure/msal-react";
import { clearLocalStorageMSAL } from "../../utils/auth";

interface ErrorModal {
  title: string;
  text: string;
  displayImage: undefined | string;
}

const redirectAfterClear = () => {
  if (window.location.href.search("/login") !== -1) {
    window.location.href = "/";
    return;
  }

  const params = new URLSearchParams(window.location.search);
  const urlRedirect = new URL(params.get("redirect") ?? window.location.href, window.location.origin);
  urlRedirect.searchParams.set("loginErrorBefore", "true");
  urlRedirect.searchParams.delete("force");

  window.location.href = urlRedirect.href;
}

const handleLogin = async (isAuthenticating: boolean = false, isAuthenticated: boolean = false, instance: IPublicClientApplication) => {
  if (isAuthenticating) return;

  const account = instance.getActiveAccount();
  const force = new URLSearchParams(window.location.search).get("force") === "true";

  if ((isAuthenticated && !account) || force) {
    clearLocalStorageMSAL();
    redirectAfterClear();
    return;
  }

  const loginError = new URLSearchParams(window.location.search).get("logout");

  if (!loginError) {
    await login(instance);
  }
}

export default function Login() {
  const [errorModal, setErrorModal] = useState<ErrorModal>({
    title: 'Erro no login',
    text: 'Não foi possível realizar seu login. Entre em contato com o administrador do sistema.',
    displayImage: undefined,
  });
  const { instance, inProgress, } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const isAuthenticating = inProgress !== InteractionStatus.None

  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const loginButtonRef = useRef(null)


  useEffect(() => {
    Promise.resolve(handleLogin(isAuthenticating, isAuthenticated, instance))
  }, [isAuthenticating, isAuthenticated, instance]);

  const handleManualLogin = async () => {
    await handleLogin(isAuthenticating, isAuthenticated, instance);
  }

  const inIframe = () => {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  const returnTextInFrameLogin = () => {
    return inIframe() ? "Estamos realizando seu login...\n Permita os pop-ups para finalizar o acesso!" : "Estamos realizando seu login..."
  }

  const returnImageInFrameLogin = () => {
    return inIframe() ? EnablePopUps : undefined
  }

  useEffect(() => {
    microsoftTeams.app.initialize();
    verifyLoginError();
  }, []);

  const verifyLoginError = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const loginError = urlParams.get('loginError') || urlParams.get('logout');

    if (loginError) {
      setShowErrorModal(true);
    }
  }

  const handleCloseErrorModal = () => {
    setShowErrorModal(false);
  }

  return (
    <>
      <Modal
        modalType="wait"
        open={isAuthenticating}
        text={returnTextInFrameLogin()}
        title="Aguarde"
        displayImage={returnImageInFrameLogin()}
      />
      <Modal
        modalType="error"
        open={showErrorModal}
        text={errorModal.text}
        title={errorModal.title}
        displayImage={errorModal.displayImage}
        buttonCloseText="Fechar"
        closeModalFunc={handleCloseErrorModal}
      />
      <div className={styles.loginContainer}>
        <div className={styles.loginLayout}>
          <div>
            <img
              src={LisLogo}
              className={styles.chatIcon}
              aria-hidden="true"
            />
            <h2 className={styles.headText}>Bem-vindo à Lis </h2>
            <p className={styles.subText}>
              Eu sou a assistente virtual da Libbs!
            </p>
          </div>

          <div>
            <button
              type="submit"
              className={`${styles.button} ${styles.group}`}
              onClick={() => handleManualLogin()}
              ref={loginButtonRef}
            >
              <span className={`${styles.icon}`}>
                <LockClosed12Filled className={styles.chatIcon} style={{ color: 'darkorange', height: "15px", width: "15px" }} />
              </span>
              Entrar com conta corporativa
            </button>
          </div>
        </div>
      </div>

    </>
  )
}