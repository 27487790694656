import { useNavigate } from 'react-router-dom';
import LisLogo from "../../assets/LisLogo-Verde.png";

const LisLogotip = (props : any) => {
    const navigate = useNavigate();

    const onLogoClick = () => {
        navigate('/');
    }

    return (
        <img {...props} src={LisLogo} alt="Logo Lis, clique para retornar à home" style={{ cursor: 'pointer' }} onClick={onLogoClick} />
    );
};

export default LisLogotip;