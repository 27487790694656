
import * as React from "react";
import {
  buttonClassNames,
  makeStyles,
  shorthands,
  tokens,
  Button,
  Spinner,
} from "@fluentui/react-components";
import { CheckmarkFilled, DismissFilled } from "@fluentui/react-icons";

const useStyles = makeStyles({
  wrapper: {
    columnGap: "15px",
    display: "flex",
  },
  buttonNonInteractive: {
    ...shorthands.border(
      tokens.strokeWidthThin,
      "solid",
      tokens.colorNeutralStroke1
    ),
    cursor: "pointer",
  },
});

type LoadingState = "initial" | "loading" | "loaded" | "error";

interface ButtonLoadingProps {
  onClickFunction: () => "loaded" | "error" | Promise<"loaded" | "error">;
  onSuccessAction?: () => void;
  onErrorAction?: () => void;
  initialTextButton: string;
  loadingTextButton: string;
  loadedTextButton: string;
  backgroundColor?: string;
  textColor?: string;
}

export const LoadingButton = (props: ButtonLoadingProps) => {
  const styles = useStyles();

  const [loadingState, setLoadingState] =
    React.useState<LoadingState>("initial");


  const onButtonClick = async () => {
    try {
      setLoadingState("loading");
      const result = await props.onClickFunction();
      setLoadingState(result);
      if(result == "error"){
        if(props.onErrorAction){
          props.onErrorAction();
        }
        setTimeout(() => setLoadingState("initial"), 3500);
      }else{
        if(props.onSuccessAction){
          props.onSuccessAction();
        }
      }
    } catch (error) {
      setLoadingState("error")
    }
  };

  const buttonContent =
    loadingState === "loading"
      ? props.loadingTextButton
      : loadingState === "loaded"
        ? props.loadedTextButton
        : props.initialTextButton;

  const buttonIcon =
    loadingState === "loading" ? (
      <Spinner size="tiny" color="white" />
    ) : loadingState === "loaded" ? (
      <CheckmarkFilled color="white" />
    ) : loadingState === "error" ? (
      <DismissFilled color="white" />
    ) : null;

  const buttonClassName =
    loadingState === "initial" ? undefined : styles.buttonNonInteractive;

  const onResetButtonClick = () => {
    setLoadingState("initial");
  };

  return (
    <div className={styles.wrapper}>
      <Button
        className={buttonClassName}
        disabledFocusable={loadingState !== "initial"}
        icon={buttonIcon}
        onClick={onButtonClick}
        aria-disabled={false}
        style={
          {
            backgroundColor: props.backgroundColor || "#517D7F",
            color: props.textColor || "white"
          }
        }
      >
        {buttonContent}
      </Button>
    </div>
  );
};