import { Stack } from "@fluentui/react"
import styles from './AdminPage.module.css'
import LisLogo from "../../assets/LisLogo-Verde.png";
import { TabListComponent } from "../../components/TabList/TabList";
import Assistants from "./assistants/Assistants";
import { memo } from "react";
import Models from "./models/Models";
import Roles from "./roles/Roles";
import LisLogotip from "../../components/Logo/LisLogo";

// Componentes memoizados
const MemoizedAssistants = memo(() => <Assistants />);
const MemoizedModels = memo(() => <Models />);
const MemoizedRoles = memo(() => <Roles />);

const renderComponentsFunction = (selectedValue : any) => {
    return (
        <>
            {selectedValue === "assistant" && <MemoizedAssistants />}
            {selectedValue === "models" && <MemoizedModels />}
            {selectedValue === "roles" && <MemoizedRoles />}
        </>
    );
}

const tabsList = [
    { id: "assistant", text: "Assistentes" },
    { id: "models", text: "Modelos" },
    { id: "roles", text: "Roles" },
]

const AdminPage = (props : any) => {
    
    return (
        <>
            <Stack horizontal className={styles.adminRoot}>
                <div className={styles.adminContainer}>
                    <div className={styles.adminHeader}>
                        <LisLogotip className={styles.LisIcon} aria-hidden="true" />
                    </div>
                    <div className={styles.adminPageContainer}>
                        <TabListComponent tabsList={tabsList} renderComponentsFunction={renderComponentsFunction} boolVertical={true} />
                    </div>
                </div>
            </Stack>
        </>
    )
}

export default AdminPage;