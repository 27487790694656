export type AskResponse = {
    answer: string;
    citations: Citation[];
    error?: string;
    id?: string;
};

export type AIModel = {
    host: string;
    id: string;
    model: string;
}

export type AssistantModel = {
    description: string;
    frequency_penalty: string | null;
    id: string;
    max_tokens: number;
    model: {
        host: string;
        id: string;
        model: string;
    }
    model_id: string;
    presence_penalty: string | null;
    profile: string;
    roles: Array<{
        id: number;
        role: string;
    }>,
    search_index: string | null;
    start_message: string | null;
    stop_sequences: string | null;
    system_prompt: string;
    temperature: number;
    top_p: number;
};

export type AssistantConversationRequest = {
    messages: ChatMessage[];
    assistant: AssistantModel;
    history_metadata: {
        conversation_id?: string | null;
    }
};

export type Citation = {
    content: string;
    id: string;
    title: string | null;
    filepath: string | null;
    filename: string | null;
    url: string | null;
    metadata: string | null;
    chunk_id: string | null;
    reindex_id: string | null;
    fileOrigin: string | null;
}

export type ToolMessageContent = {
    citations: Citation[];
    intent: string;
}

export type ChatMessage = {
    id: string;
    role: string;
    content: string;
    end_turn?: boolean;
    date: string;
    user_feedback?: boolean | null;
};

export type AssistantChatMessage = {
    id: string;
    role: string;
    content: string;
    end_turn?: boolean;
    date: string;
    sources: Citation[],
    user_feedback: boolean | null;
};

export type AssistantFeedbackRequest = {
    id: string;
    user_feedback: boolean;
    assistant_id: string;
};

export type Conversation = {
    id: string | null;
    title: string;
    messages: ChatMessage[];
    date: string;
    assistant_id?: string;
}

export enum ChatCompletionType {
    ChatCompletion = "chat.completion",
    ChatCompletionChunk = "chat.completion.chunk"
}

export type ChatResponseChoice = {
    messages: ChatMessage[];
}

export type SuggestPrompts = {
    promptSuggestions: string[];
}

export type ChatResponse = {
    id: string;
    model: string;
    created: number;
    object: ChatCompletionType;
    choices: ChatResponseChoice[];
    history_metadata: {
        conversation_id: string;
        title: string;
        date: string;
    }
    error?: any;
}

export type ConversationRequest = {
    messages: ChatMessage[];
    selectedIndex?: string | null;
    history_metadata: {
        conversation_id?: string | null;
        assistant_id?: string;
    }
}; 

export type FeedbackRequest = {
    id: string;
    user_feedback: boolean;
};

export type FeedbackResponse = {
    conversation_id: string;
    id: string;
    user_feedback: boolean;
};

export type UserInfo = {
    access_token: string;
    expires_on: string;
    id_token: string;
    provider_name: string;
    user_claims: any[];
    user_id: string;
};

export enum CosmosDBStatus {
    NotConfigured = "CosmosDB is not configured",
    NotWorking = "CosmosDB is not working",
    Working = "CosmosDB is configured and working",
}

export type CosmosDBHealth = {
    cosmosDB: boolean,
    status: string
}

export enum ChatHistoryLoadingState {
    Loading = "loading",
    Success = "success",
    Fail = "fail",
    NotStarted = "notStarted"
}

export type ErrorMessage = {
    title: string,
    subtitle: string
}

export type ErrorModal = {
    title: string;
    text: string;
    displayImage: undefined | string;
}

export type IndexList = {
    name: string,
    description: string,
    indexes: Array<string>
}

export type ResultModalProps = {
    showResult: boolean;
    titleResult: string;
    result: string;
    typeResult: "info" | "error" | "wait" | undefined;
    buttonDoSomethingOnClick?: () => void
    buttonDoSomethingText?: string
}

export type Role = {
    id: number;
    role: string;
}
export type User = {
    name: string | null | undefined;
    email: string | null;
    roles: string[] | undefined;
}