import { createContext } from "react"
import { ChatMessage } from "../../../api/models"
import { Dispatch } from "react"

interface IChatContext {
  messages: ChatMessage[]
  setMessages: Dispatch<React.SetStateAction<ChatMessage[]>>
}

export const ChatContext = createContext({
  messages: [],
  setMessages: () => {}
} as IChatContext)