import { AbilityBuilder, MongoAbility, CreateAbility, createMongoAbility } from "@casl/ability";
import rbacSchema from "../../../../security/rbac_schema.json"

export type Actions =
    | "create"
    | "read"
    | "update"
    | "delete"
    | "chat";

type Domains = "AIModel" | "Role" | "Assistant";

type RolePermission = {
    role: string;
    permissions: Actions[];
};

type DomainPermission = {
    name: Domains;
    roles_permission: RolePermission[]
}

const domainPermissions = rbacSchema.domains as DomainPermission[];

export type AppAbility = MongoAbility<[Actions, Domains]>;
export const appAbility = createMongoAbility as CreateAbility<AppAbility>;


export default function defineRulesFor(roles: string[]) {
    const { can, rules } = new AbilityBuilder(appAbility);

    domainPermissions.map((domainPermission) => {
        domainPermission.roles_permission.map((rolePermission) => {
            if (roles.includes(rolePermission.role)) {
                rolePermission.permissions.map((permission) => {
                    can(permission, domainPermission.name);
                })
            }
        })
    });
    
    return rules;
}

export function defineAbilityFor(roles: string[]): AppAbility {
    return appAbility(defineRulesFor(roles));
}