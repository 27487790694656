import { Label, Stack } from '@fluentui/react';
import styles from './AssistantForm.module.css';
import {
    Checkbox,
    Combobox,
    Field,
    Input,
    makeResetStyles,
    Option,
    Radio,
    RadioGroup,
    Slider,
    SpinButton,
    Switch,
    Textarea,
    tokens,
    useId,
    makeStyles,
    SliderProps,
    SpinButtonProps,
    TextareaProps,
    InputProps,
    shorthands,
    typographyStyles,
    ComboboxProps,
    Dropdown
} from "@fluentui/react-components";
import { useLocation, useNavigate } from "react-router-dom";
import LisLogo from "../../../assets/LisLogo-Verde.png";
import { useCallback, useEffect, useState } from 'react';
import { on } from 'events';
import { AIModel, AssistantModel, ResultModalProps, Role, listAIModelsApi, listRolesApi, saveAssistantsApi } from '../../../api';
import { LoadingButton } from '../../../components/LoadingButton/LoadingButton';
import { SimpleButton } from '../../../components/Button/Button';
import { profile } from 'console';
import { getAccessToken } from '../../../authConfig';
import { useMsal } from '@azure/msal-react';
import { Modal } from '../../../components/modal/Modal';
import { set } from 'lodash';
import LisLogotip from '../../../components/Logo/LisLogo';

const useStackClassName = makeResetStyles({
    display: "flex",
    flexDirection: "column",
    rowGap: tokens.spacingVerticalL,
});

const useStyles = makeStyles({
    wrapper: {
        display: "flex",
        alignItems: "center",
    },
    root: {
        // Stack the label above the field with a gap
        display: "grid",
        gridTemplateRows: "repeat(1fr)",
        justifyItems: "start",
        ...shorthands.gap("2px"),
        maxWidth: "400px",
    },
    description: {
        ...typographyStyles.caption1,
    },
});

export const AssistantForm = (props: any) => {
    const { instance } = useMsal();
    const navigation = useNavigate();
    const location = useLocation();
    const item: AssistantModel = location.state?.item || {};
    const styless = useStyles();
    const id = useId();

    //Assistant ID
    const [assistantIdValidation, setAssistantIdValidation] = useState<{ validationState: string; validationMessage: string } | null>(null);
    const [assistantId, setAssistantId] = useState(item.id || null);
    const onAssistantIdChange: InputProps["onChange"] = (ev, data) => {
        // The controlled input pattern can be used for other purposes besides validation,
        // but validation is a useful example
        if (data.value.length <= 40) {
            validateAssistantId(data.value)
            setAssistantId(data.value);
        }
    };

    const validateAssistantId = (value: string) => {
        const regex = /^[a-zA-Z0-9_-]*$/;
        if (!regex.test(value)) {
            console.log('teste pegou erro')
            setAssistantIdValidation({ validationState: 'error', validationMessage: 'O ID da assistente deve conter apenas letras, números, hífens e underscores.' })
        }
        else {
            console.log('teste passou')
            setAssistantIdValidation(null);
        }
    }

    //Assistant Profile
    const [assistantProfile, setAssistantProfile] = useState(item.profile || null);
    const onAssistantProfileChange: InputProps["onChange"] = (ev, data) => {
        // The controlled input pattern can be used for other purposes besides validation,
        // but validation is a useful example
        if (data.value.length <= 40) {
            setAssistantProfile(data.value);
        }
    };

    //Assistant Description
    const [descAssistantValue, setDescAssistantValue] = useState(item.description || null);
    const onChangeDescAssistant: TextareaProps["onChange"] = (ev, data) => {
        if (data.value.length <= 200) {
            setDescAssistantValue(data.value);
        }
    };

    //Start Message
    const [startMessageValue, setStartMessageValue] = useState(item.start_message || null);
    const onChangeStartMessage: TextareaProps["onChange"] = (ev, data) => {
        if (data.value.length <= 600) {
            setStartMessageValue(data.value);
        }
    };

    //System Prompt
    const [systemPromptValue, setSystemPrompt] = useState(item.system_prompt || "");
    const onChangeSystemPrompt: TextareaProps["onChange"] = (ev, data) => {
        if (data.value.length <= 10000) {
            setSystemPrompt(data.value);
        }
    };

    //AI Model
    const [modelsList, setModelsList] = useState<AIModel[]>([]);
    const [modelTitle, setModelTitle] = useState("");
    const [selectedModel, setSelectedModel] = useState<{ id: string; model: string; host: string; }>(item.model || null);

    const onModelSelect: ComboboxProps["onOptionSelect"] = (event, data) => {
        const newSelected = modelsList.filter((model) => data.selectedOptions.includes(model.id.toString()))[0];
        setSelectedModel(newSelected);
        setModelTitle(newSelected.model);
    };

    //Temperature
    const [temperatureValue, setTemperatureValue] = useState(item.temperature || 0);
    const onTemperatureChange: SliderProps["onChange"] = (_, data) =>
        setTemperatureValue(data.value);

    //Top P
    const [topPValue, setTopPValue] = useState(item.top_p || 1);
    const onTopPChange: SliderProps["onChange"] = (_, data) =>
        setTopPValue(data.value);


    // Max tokens
    const [maxTokensValue, setMaxTokensValue] = useState<number | null>(item.max_tokens || 100);
    const onMaxTokensChange: SpinButtonProps["onChange"] = useCallback(
        (_ev: any, data: any) => {
            if (data.value !== undefined) {
                setMaxTokensValue(data.value);
            } else if (data.displayValue !== undefined) {
                const newValue = parseFloat(data.displayValue);
                if (!Number.isNaN(newValue)) {
                    setMaxTokensValue(newValue);
                } else {
                    console.error(`Cannot parse "${data.displayValue}" as a number.`);
                }
            }
        },
        [setMaxTokensValue]
    );

    //Stop Sequence
    const [stopSequence, setStopSequence] = useState(item.stop_sequences || null);
    const onStopSequenceChange: InputProps["onChange"] = (ev, data) => {
        // The controlled input pattern can be used for other purposes besides validation,
        // but validation is a useful example
        if (data.value.length <= 200) {
            setStopSequence(data.value);
        }
    };

    //Penalty Frequency
    const [penaltyFrequency, setPenaltyFrequency] = useState(item.frequency_penalty || null);
    const onPenaltyFrequencyChange: InputProps["onChange"] = (ev, data) => {
        // The controlled input pattern can be used for other purposes besides validation,
        // but validation is a useful example
        if (data.value.length <= 200) {
            setPenaltyFrequency(data.value);
        }
    };

    //Presence Penalty
    const [presencePenalty, setPresencePenalty] = useState(item.presence_penalty || null);
    const onPresencePenaltyChange: InputProps["onChange"] = (ev, data) => {
        // The controlled input pattern can be used for other purposes besides validation,
        // but validation is a useful example
        if (data.value.length <= 200) {
            setPresencePenalty(data.value);
        }
    };

    //Search Index
    const [searchIndex, setSearchIndex] = useState(item.search_index || null);
    const onSearchIndexChange: InputProps["onChange"] = (ev, data) => {
        // The controlled input pattern can be used for other purposes besides validation,
        // but validation is a useful example
        if (data.value.length <= 60) {
            setSearchIndex(data.value);
        }
    };

    //Access Roles
    const comboId = useId("combo-multi");
    const [rolesList, setRolesList] = useState<Role[]>([]);
    const [selectedRoles, setSelectedRoles] = useState<Array<{ id: number; role: string; }>>(item.roles || null);
    const [selectedRolesTitle, setSelectedRolesTitle] = useState<string[]>([]);

    const onSelect: ComboboxProps["onOptionSelect"] = (event, data) => {
        const newSelected = rolesList.filter((role) => data.selectedOptions.includes(role.role));
        console.log(data.selectedOptions)
        console.log(newSelected)
        setSelectedRoles(newSelected);
        setSelectedRolesTitle(data.selectedOptions);
    };

    useEffect(() => {
        try {
            const fetchModels = async () => {
                const response = await listAIModelsApi(await getAccessToken(instance));
                if (response?.body) {
                    console.log('response com body')
                    const result: AIModel[] = await response.json();
                    console.log(result)
                    setModelsList(result);
                }
                else {
                    console.log('response sem body')
                    setModelsList([]);
                }
            };
            const fetchRoles = async () => {
                const response = await listRolesApi(await getAccessToken(instance));
                if (response?.body) {
                    console.log('response com body')
                    const result: Role[] = await response.json();
                    console.log(result)
                    setRolesList(result);
                }
                else {
                    console.log('response sem body')
                    setRolesList([]);
                }
            };
            Promise.resolve(fetchModels());
            Promise.resolve(fetchRoles());
            if (selectedModel && selectedModel !== null) {
                setModelTitle(selectedModel.model);
            }
            if (selectedRoles) {
                let rolesInitial = selectedRoles.map(option => option.role);
                setSelectedRolesTitle(rolesInitial);
            }
        } catch (error) {
            console.log('error', error);
        }
    }, []);

    const navigateBack = () => {
        if (history.length > 2) {
            navigation(-1)
        } else {
            navigation('/admin');
        }
    }

    const [isCancelDialogVisible, setIsCancelDialogVisible] = useState<boolean>(false);
    const [saveResult, setSaveResult] = useState<ResultModalProps>({ showResult: false, titleResult: '', result: '', typeResult: undefined });

    const handleSaveAssistantButton = async (): Promise<"error" | "loaded"> => {
        if (
            assistantId === null || assistantId === '' ||
            assistantProfile === null || assistantProfile === '' ||
            descAssistantValue === null || descAssistantValue === '' ||
            systemPromptValue === null || systemPromptValue === '' ||
            selectedModel === null ||
            temperatureValue === null ||
            topPValue === null ||
            maxTokensValue === null
        ) {
            console.log("erro na validação dos campos")
            setSaveResult({ showResult: false, titleResult: 'Campos obrigatorios', result: 'Preencha todos os campos obrigatórios para salvar a assistente.', typeResult: "error" });
            return "error";
        }
        const oldId = item.id || "";
        const assistant: Partial<AssistantModel> = {
            id: assistantId,
            profile: assistantProfile,
            description: descAssistantValue,
            start_message: startMessageValue,
            system_prompt: systemPromptValue,
            model_id: selectedModel.id,
            temperature: temperatureValue,
            top_p: topPValue,
            max_tokens: maxTokensValue,
            stop_sequences: stopSequence,
            frequency_penalty: penaltyFrequency,
            presence_penalty: presencePenalty,
            search_index: searchIndex,
            roles: selectedRoles,
        }

        let method: "PUT" | "POST";
        if (location.state?.item) {
            method = "PUT";
        }
        else {
            method = "POST";
        }

        const response = await saveAssistantsApi(await getAccessToken(instance), assistant, method, oldId);
        if (response.status >= 200 && response.status < 300) {
            console.log('Salvo com sucesso')
            return "loaded";
        }
        else {
            console.log('Erro ao salvar')
            const body = await response.json();
            setSaveResult({ showResult: false, titleResult: 'Erro ao salvar assistente', result: body.description || 'Ocorreu um erro inesperado ao salvar a assitente', typeResult: "error" });
            return "error";
        }
    }

    const handleSuccessSaveAssistant = () => {
        setTimeout(() => {
            setSaveResult({ showResult: false, titleResult: '', result: '', typeResult: undefined });
            navigateBack();
        }, 3500);
    }

    const handleErrorSaveAssistant = () => {
        setSaveResult(prevSaveResult => ({
            ...prevSaveResult,
            showResult: true
        }));
    }

    const showCancelDialog = () => {
        setIsCancelDialogVisible(true);
    };

    const closeCancelDialog = () => {
        setIsCancelDialogVisible(false);
    };

    const closeErrorDialog = () => {
        setSaveResult({ showResult: false, titleResult: '', result: '', typeResult: undefined });
    }

    return (
        <>
            <Stack horizontal className={styles.adminRoot}>
                <div className={styles.adminContainer}>
                    <div className={styles.adminHeader}>
                        <LisLogotip className={styles.LisIcon} aria-hidden="true" />
                    </div>
                    <div className={styles.formContainer}>
                        <div className={styles.fieldsContainer}>
                            <div className={styles.formColumn}>
                                <Field
                                    label="ID da assistente"
                                    validationState={assistantIdValidation !== null ? assistantIdValidation.validationState : null}
                                    validationMessage={assistantIdValidation !== null ? assistantIdValidation.validationMessage : null}
                                    {...props}
                                    required
                                >
                                    <Input value={assistantId || ""} onChange={onAssistantIdChange} />
                                </Field>
                                <Field label="Perfil da assistente" required>
                                    <Input value={assistantProfile || ""} onChange={onAssistantProfileChange} />
                                </Field>
                                <Field label="Descrição da assistente" required>
                                    <Textarea resize="vertical" value={descAssistantValue || ""} onChange={onChangeDescAssistant} />
                                </Field>
                                <Field label="Mensagem de início de conversa">
                                    <Textarea resize="vertical" value={startMessageValue || ""} onChange={onChangeStartMessage} />
                                </Field>
                                <Field label="Prompt de sistema" required className={styles.systemPromptFieldContainer}>
                                    <Textarea resize="vertical" value={systemPromptValue} onChange={onChangeSystemPrompt} size='large' className={styles.systemPromptField} />
                                </Field>
                                
                            </div>
                            <div className={styles.formColumn}>
                                <Field label="Roles de acesso" required>
                                    <Dropdown
                                        aria-labelledby={comboId}
                                        multiselect={true}
                                        placeholder="Selecione uma role"
                                        {...props}
                                        onOptionSelect={onSelect}
                                        selectedOptions={selectedRolesTitle}
                                        value={selectedRolesTitle.join(", ")}
                                    >
                                        {
                                            rolesList.map((role) => (<Option key={role.role} value={role.role}>{`${role.id} - ${role.role}`}</Option>))
                                        }
                                    </Dropdown>
                                </Field>
                                <Field label="Modelo de IA" required>
                                    <Dropdown
                                        placeholder="Selecion um modelo"
                                        {...props}
                                        onOptionSelect={onModelSelect}
                                        value={modelTitle}
                                    >
                                        {
                                            modelsList.map((model) => (<Option key={model.id} value={model.id}>{`${model.host} - ${model.model}`}</Option>))
                                        }
                                    </Dropdown>
                                </Field>
                                <Field label="Temperatura" required>
                                    <div className={styless.wrapper}>
                                        <Slider
                                            min={0} max={1}
                                            value={temperatureValue}
                                            onChange={onTemperatureChange}
                                            step={0.1}
                                        />
                                        <span aria-hidden>{temperatureValue}</span>
                                    </div>
                                </Field>
                                <Field label="Top P" required>
                                    <div className={styless.wrapper}>
                                        <Slider
                                            min={0} max={1}
                                            value={topPValue}
                                            onChange={onTopPChange}
                                            step={0.1}
                                        />
                                        <span aria-hidden>{topPValue}</span>
                                    </div>
                                </Field>
                                <Field label="Máx. tokens" required>
                                    <SpinButton
                                        min={0}
                                        max={4000}
                                        value={maxTokensValue}
                                        onChange={onMaxTokensChange}
                                        id={id}
                                    />
                                </Field>
                                <Field label="Sequência de parada">
                                    <Input value={stopSequence || ""} onChange={onStopSequenceChange} />
                                </Field>
                                <Field label="Frequência de penalidade">
                                    <Input value={penaltyFrequency || ""} onChange={onPenaltyFrequencyChange} />
                                </Field>
                                <Field label="Penalidade de presença">
                                    <Input value={presencePenalty || ""} onChange={onPresencePenaltyChange} />
                                </Field>
                                <Field label="Índice de pesquisa">
                                    <Input value={searchIndex || ""} onChange={onSearchIndexChange} />
                                </Field>
                            </div>

                        </div>
                        <div className={styles.buttonsContainer}>
                            <SimpleButton onClickFunction={showCancelDialog} buttonText="Cancelar" backgroundColor="#878787" textColor="white" />
                            <LoadingButton onClickFunction={handleSaveAssistantButton} initialTextButton="Salvar" loadingTextButton="Salvando" loadedTextButton="Salvo" onSuccessAction={handleSuccessSaveAssistant} onErrorAction={handleErrorSaveAssistant} />
                        </div>
                    </div>
                </div>
            </Stack>

            <Modal
                open={isCancelDialogVisible}
                closeModalFunc={closeCancelDialog}
                doSomethingFunc={navigateBack}
                title="Cancelar"
                text="Tem certeza de que deseja cancelar?"
                buttonDoSomethingText="Cancelar"
                buttonCloseText="Permanecer"
                modalType="info"
            />
            <Modal
                open={saveResult.showResult}
                title={saveResult.titleResult}
                text={saveResult.result}
                modalType={saveResult.typeResult}
                buttonCloseText="Fechar"
                closeModalFunc={closeErrorDialog}
            />
        </>

    )

};