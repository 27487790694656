import {
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
  Button,
} from "@fluentui/react-components";
import { Spinner } from "@fluentui/react-components";
import type { SpinnerProps } from "@fluentui/react-components";
import styles from './Modal.module.css';
import { ErrorCircle24Regular, Info24Regular } from '@fluentui/react-icons';
import { ReactElement, useContext } from "react";
import { ThemeContext } from "../../pages/Panel/Context/Theme";
import { darkTheme, lightTheme } from "../../styles/theme";

interface ModalProps {
  modalType?: 'error' | 'wait' | 'info' | undefined;
  color?: string;
  open: boolean;
  title: string;
  text: string | ReactElement;
  buttonCloseText?: string;
  buttonDoSomethingText?: string;
  doSomethingFunc?: () => void;
  closeModalFunc?: () => void;
  displayImage?: string;
}

export const Modal = (props: ModalProps) => {
  const { isDarkTheme } = useContext(ThemeContext)

  const handleCloseModalFunc = () => {
    if (props.closeModalFunc) {
      props.closeModalFunc();
    }
  }

  const handleDoSomethinModalFunc = () => {
    if (props.doSomethingFunc) {
      props.doSomethingFunc();
    }
  }

  const modalTypeObject = () => {
    switch (props.modalType) {
      case 'error':
        return <ErrorCircle24Regular style={{ color: '#f05348', alignSelf: 'center' }} />
      case 'wait':
        return <Spinner />
      case 'info':
        return <Info24Regular style={{ color: isDarkTheme === "dark" ? darkTheme.colors.icon : lightTheme.colors.icon, alignSelf: 'center' }} />
      default:
        return null
    }
  }

  return (
    <Dialog open={props.open} >
      <DialogSurface
        style={{
          background: isDarkTheme === "dark" ? darkTheme.colors.background : lightTheme.colors.background
        }}
      >
        <DialogBody className={styles.bodyModal}>
          <div className={styles.contentModal}>
            {
              modalTypeObject()
            }
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                color: isDarkTheme === "dark" ? darkTheme.colors.color : lightTheme.colors.color
              }}
            >
              <DialogTitle>{props.title}</DialogTitle>
              <DialogContent className={styles.modalTextContainer}>
                {props.text}
              </DialogContent>
              {
                props.displayImage && (
                  <img
                    src={props.displayImage}
                    className={styles.chatIcon}
                    aria-hidden="true"
                  />
                )
              }
            </div>
          </div>
          <DialogActions>
            {
              props.buttonCloseText && (
                <DialogTrigger disableButtonEnhancement>
                  <Button appearance="secondary" onClick={() => handleCloseModalFunc()}>{props.buttonCloseText}</Button>
                </DialogTrigger>
              )
            }
            {
              props.buttonDoSomethingText && (
                <Button
                  style={{
                    background: isDarkTheme === "dark" ? darkTheme.colors.backgroundButton : lightTheme.colors.backgroundButton,
                    color: isDarkTheme === "dark" ? darkTheme.colors.colorButton : lightTheme.colors.colorButton
                  }}
                  appearance="primary"
                  onClick={() => handleDoSomethinModalFunc()}
                >
                  {props.buttonDoSomethingText}
                </Button>
              )
            }
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
}