import { Link, Outlet } from "react-router-dom";
import styles from "./Layout.module.css";
import { useContext, useEffect, useState } from "react";
import { AppStateContext } from "../../state/AppProvider";
import { UserSection } from "../../components/SideBar/UserSection";
import ChatHistoryList from "../../components/ChatHistory/ChatHistoryList";
import { UseTerms } from "../../components/dialog/UseTerms";



const Layout = () => {
    const appStateContext = useContext(AppStateContext)


    const handleHistoryClick = () => {
        appStateContext?.dispatch({ type: 'TOGGLE_CHAT_HISTORY' })
    };


    useEffect(() => { }, [appStateContext?.state.isCosmosDBAvailable.status]);

    const inIframe = () => {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }

    const handleRegisterPreviousLogin = () => {
        try {
            localStorage.setItem('previousLogin', JSON.stringify(true));
        } catch (error) {
            console.error('Erro ao salvar atributo previousLogin:', error);
        }
    }

    useEffect(() => { 
        handleRegisterPreviousLogin();
    }, [appStateContext?.state.isCosmosDBAvailable.status]);

    return (
        <div className={styles.layout}>
            <UseTerms buttonAcceptText="Aceitar termos" />
            <div className={styles.container}>

                <div className={inIframe() ? styles.sideBarIframe : styles.sideBar}>
                    <ChatHistoryList />
                    <UserSection />
                </div>
                <Outlet />
            </div>
        </div>
    );
};

export default Layout;
