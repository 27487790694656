import { IconButton, Icon, Separator } from "@fluentui/react";
import styles from "./UserSection.module.css";
import LisLogo from "../../assets/LisLogo-Verde.png";
import { useEffect, useRef, useState } from "react";
import { getUserInfo, UserInfo } from "../../api";
import NoProfilePicture from '../../assets/no-profile-icon.jpg';
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import {
  Button,
  Menu,
  MenuTrigger,
  MenuList,
  MenuItem,
  MenuPopover,
  PositioningImperativeRef,
} from "@fluentui/react-components";
import { useMediaQuery } from "react-responsive";

interface Props {
  onSend: (question: string, id?: string) => void;
  disabled: boolean;
  placeholder?: string;
  clearOnSend?: boolean;
  conversationId?: string;
}

export const UserSection = () => {
  const [proflePicture, setProfilePicture] = useState<string>();
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const menuTriggerRef = useRef<HTMLButtonElement | null>(null);
  const divRef = useRef<HTMLDivElement | null>(null);
  const positioningRef = useRef<PositioningImperativeRef>(null);
  const isMobile = useMediaQuery({ maxWidth: 767 }); // Define a largura para considerar como mobile

  const handleUserSectionClick = () => {
    // Simule o clique no botão resetButtonStyles
    if (menuTriggerRef.current) {
      menuTriggerRef.current.click();
    }
  };

  const getProfilePicture = async (accessToken: string) => {
    setProfilePicture(NoProfilePicture);

    if (accessToken) {
      const response = await fetch("https://graph.microsoft.com/v1.0/me/photo/$value", {
        method: "GET",
        headers: {
          "Accept": "*/*",
          "Accept-Encoding": "gzip, deflate, br",
          "Authorization": "Bearer " + accessToken
        }
      });

      if (response.status == 200) {
        const imageBlob = await response.blob();
        const imageObjectURL = URL.createObjectURL(imageBlob);
        setProfilePicture(imageObjectURL);
      }
    }

  }

  useEffect(() => {
    // Verifica se o usuário está autenticado
    if (isAuthenticated) {
      // Define as configurações de token
      const request = {
        scopes: ['User.Read'],
        account: accounts[0], // Você pode ajustar isso de acordo com sua lógica de gerenciamento de contas
      };
      // Obtém o token de acesso
      instance.acquireTokenSilent(request).then(response => {
        getProfilePicture(response.accessToken)
      }).catch(error => {
        console.error('Erro ao obter AccessToken:', error);
      });
    }
  }, [isAuthenticated, instance, accounts]);

  useEffect(() => {
    if (divRef.current) {
      positioningRef.current?.setTarget(divRef.current);
    }
  }, [divRef, positioningRef]);

  const handleLogout = async () => {
    const currentAccount = await instance.getActiveAccount();
    if (inIframe()) {
      instance.logoutPopup({
        account: currentAccount
      });
    }
    else {
      instance.logoutRedirect({
        idTokenHint: currentAccount?.idToken,
        account: currentAccount
      });
    }
  }

  const inIframe = () => {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  return (
    <div className={styles.userContainer}>
      <Separator className={styles.separator} />
      <div className={styles.mobileCentalizer}></div>
      <div className={styles.chatIconContainer}>
        <img
          src={LisLogo}
          className={styles.chatIcon}
          aria-hidden="true"
        />
      </div>
      <div className={styles.userSectionContainer} ref={isMobile ? divRef : undefined}>
        <div className={styles.userIconContainer} onClick={isMobile ? handleUserSectionClick : undefined} >
          <img className={styles.userIcon} src={proflePicture} />
        </div>

        <Menu positioning={isMobile ? { positioningRef } : undefined}>
          <MenuTrigger disableButtonEnhancement>
            <button ref={menuTriggerRef} className={styles.resetButtonStyles}><Icon className={styles.moreIcon} iconName='MoreVertical' onClick={() => { }} /></button>
          </MenuTrigger>

          <MenuPopover>
            <MenuList>
              <MenuItem onClick={() => handleLogout()}>Sair da conta</MenuItem>
            </MenuList>
          </MenuPopover>
        </Menu>
      </div>
    </div>
  );
};
