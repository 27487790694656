import { ReactComponentElement, ReactElement, useEffect, useState } from 'react';
import { Modal } from '../modal/Modal';
import styles from './KnownTopicsModal.module.css';

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import supersub from 'remark-supersub'
import { AssistantModel, ChatMessage, ChatResponse, Conversation, ConversationRequest, assistantKnownTopicsApi, knownTopicsApi } from '../../api';
import { Spinner } from "@fluentui/react-components";
import { Stack } from '@fluentui/react';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import { handleGetTokenError } from "../../utils/auth"

interface ModalType {
    title: string;
    text: string | ReactElement;
    displayImage: undefined | string;
}

interface PropsType {
    idx?: string;
    assistant?: AssistantModel | null;
    getAccessTokenFunction: (instance : any) => Promise<string>;
}

export const KnownTopicsModal = (props: PropsType) => {
    const { instance } = useMsal();
    const [knownTopicsModal, setKnownTopicsModal] = useState<ModalType>({
        title: 'Assuntos conhecidos pela Lis',
        text: "",
        displayImage: undefined,
    });
    const [showModal, setShowModal] = useState<boolean>(false);
    const [loadedInfo, setLoadedInfo] = useState<boolean>(false);
    const [topics, setTopics] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const navigate = useNavigate();

    const handleCloseModal = () => {
        setShowModal(false);
    }

    const returnReactMarkdownObject = () => {
        let objectReturn = <Stack className={styles.spinnerContainer}><Spinner /></Stack>

        if (!isLoading) {
            objectReturn = <ReactMarkdown
                linkTarget="_blank"
                remarkPlugins={[remarkGfm, supersub]}
                children={topics}
                className={styles.answerText}
            />
        }

        return objectReturn;
    }

    const makeKnownTopicsApiRequest = async () => {
        setIsLoading(true);
        let result = {} as ChatResponse;
        try {
            let response
            const token = await props.getAccessTokenFunction(instance);

            if (!token) {
                handleGetTokenError(navigate)
            }

            if(props.assistant){
                response = await assistantKnownTopicsApi(token, props.assistant.id);
            } else if (props.idx){
                response = await knownTopicsApi(props.idx, token);
            }
            if (response?.body) {
                if (response.headers?.get("Content-Type") == "application/json") {
                    result = await response.json();
                }
                else {
                    setIsLoading(false);
                    const reader = response.body.getReader();
                    let runningText = "";

                    while (true) {
                        const { done, value } = await reader.read();
                        if (done) break;

                        var text = new TextDecoder("utf-8").decode(value);
                        const objects = text.split("\n");
                        objects.forEach((obj) => {
                            try {
                                runningText += obj;
                                result = JSON.parse(runningText);
                                setTopics(result.choices[0].messages[0].content);
                                runningText = "";
                            }
                            catch { }
                        });
                    }
                }

                setTopics(result.choices[0].messages[0].content);
            }

        } catch (e) {
            console.log(e)
            let errorMessage = "Falha ao carregar assuntos conhecidos. Tente novamente, se o problema persistir, entre em contato com o administrador do sistema.";
            setTopics(errorMessage);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {

        console.log('use effect')
        console.log(loadedInfo)

        if (showModal && !loadedInfo) {
            console.log('buscando infos')
            makeKnownTopicsApiRequest().then((r) => {
                console.log(r);
            }).catch((e) => {
                console.log(e);
            })
            setLoadedInfo(true);
        }

    }, [showModal]);

    return (
        <>
            {props.idx || props.assistant?.search_index && (
                <span className={styles.knownTopicsButton} onClick={() => setShowModal(true)}>Assuntos que a Lis conhece...</span>
            )}
            <Modal
                open={showModal}
                text={returnReactMarkdownObject()}
                title={knownTopicsModal.title}
                displayImage={knownTopicsModal.displayImage}
                buttonCloseText="Fechar"
                closeModalFunc={handleCloseModal}
            />
        </>
    )
    
}