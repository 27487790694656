import {
    makeStyles,
    shorthands,
    Tab,
    TabList,
} from "@fluentui/react-components";
import type { SelectTabData, SelectTabEvent, TabListProps, TabValue } from "@fluentui/react-components";
import { ReactNode, useEffect, useState } from "react";
import styles from './TabList.module.css'

interface MyTabListProps extends TabListProps {
    tabsList: {
        id: string;
        text: string;
    }[];
    renderComponentsFunction: (selectedValue: any) => string | number | true | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | null;
    boolVertical: boolean;
}

export const TabListComponent = (props: Partial<MyTabListProps>) => {
    const [selectedValue, setSelectedValue] = useState<TabValue | string>("conditions");

    const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
        setSelectedValue(data.value);
    };

    useEffect(() => {
        if (props.tabsList && props.tabsList.length > 0) {
            setSelectedValue(props.tabsList[0].id);
        }
    }, []);

    return (
        <div className={styles.root} >
            <TabList className={styles.tabList} {...props} selectedValue={selectedValue} onTabSelect={onTabSelect} vertical={props.boolVertical} appearance="subtle" >
                {
                    props.tabsList && props.tabsList.map((tab) => {
                        return <Tab key={tab.id} value={tab.id}>{tab.text}</Tab>
                    })
                }
            </TabList>
            <div className={styles.panels}>
                {props.renderComponentsFunction && props.renderComponentsFunction(selectedValue)}
            </div>
        </div>
    );
};