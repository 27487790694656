import { ReactElement, useContext, useState } from "react";
import { CommandBarButton, Dialog, Stack, TextField } from "@fluentui/react";
import { SendRegular, Send16Filled } from "@fluentui/react-icons";
import Send from "../../assets/Send.svg";
import styles from "./QuestionInput.module.css";
import { CosmosDBStatus } from "../../api";
import { AppStateContext } from "../../state/AppProvider";

interface Props {
    onSend: (question: string, id?: string) => void;
    disabledButton: () => boolean;
    clearChat: () => void;
    hideErrorDialog: boolean;
    handleErrorDialogClose: () => void;
    errorDialogContentProps: any;
    modalProps: {
        titleAriaId: string;
        subtitleAriaId: string;
        isBlocking: boolean;
        styles: { main: { maxWidth: number } },
    }
    disabled: boolean;
    placeholder?: string;
    clearOnSend?: boolean;
    conversationId?: string;
    knownTopicsComponent: ReactElement;
    onQuestionChange: (_ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => void;
    setQuestion: (_question: string) => void;
    question: string;
}



export const QuestionInput = ({ disabledButton, clearChat, hideErrorDialog, handleErrorDialogClose, errorDialogContentProps, modalProps, onSend, disabled, placeholder, clearOnSend, conversationId, knownTopicsComponent, onQuestionChange, setQuestion, question }: Props) => {
    const appStateContext = useContext(AppStateContext);

    const sendQuestion = () => {
        if (disabled || !question.trim()) {
            return;
        }

        if (conversationId) {
            onSend(question, conversationId);
        } else {
            onSend(question);
        }

        if (clearOnSend) {
            setQuestion("");
        }
    };

    const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
        if (ev.key === "Enter" && !ev.shiftKey) {
            ev.preventDefault();
            sendQuestion();
        }
    };

    const sendQuestionDisabled = disabled || !question.trim();

    return (
        <div className={styles.questionContainer}>
            <Stack>
                {/*appStateContext?.state.isCosmosDBAvailable?.status !== CosmosDBStatus.NotConfigured && <CommandBarButton
                                    role="button"
                                    styles={{ 
                                        icon: { 
                                            color: '#FFFFFF',
                                        },
                                        root: {
                                            color: '#FFFFFF',
                                            background: "radial-gradient(109.81% 107.82% at 100.1% 90.19%, #0F6CBD 33.63%, #2D87C3 70.31%, #8DDDD8 100%)"
                                        },
                                        rootDisabled: {
                                            background: "#BDBDBD"
                                        }
                                    }}
                                    className={styles.newChatIcon}
                                    iconProps={{ iconName: 'Add' }}
                                    onClick={newChat}
                                    disabled={disabledButton()}
                                    aria-label="start a new chat button"
                                />*/}
                <CommandBarButton
                    role="button"
                    styles={{
                        icon: {
                            color: '#FFFFFF',
                        },
                        root: {
                            color: '#FFFFFF',
                            background: disabledButton() ? "#E3E1E1" : "#517D7F",
                            cursor: disabledButton() ? "" : "pointer",
                        },
                    }}
                    className={appStateContext?.state.isCosmosDBAvailable?.status !== CosmosDBStatus.NotConfigured ? styles.clearChatBroom : styles.clearChatBroomNoCosmos}
                    iconProps={{ iconName: 'Broom' }}
                    onClick={clearChat}
                    disabled={disabledButton()}
                    aria-label="clear chat button"
                />
                <Dialog
                    hidden={hideErrorDialog}
                    onDismiss={handleErrorDialogClose}
                    dialogContentProps={errorDialogContentProps}
                    modalProps={modalProps}
                >
                </Dialog>
            </Stack>
            <Stack className={styles.questionInputContainer}>
                {knownTopicsComponent}
                <Stack horizontal className={styles.questionInput}>
                    <TextField
                        className={styles.questionInputTextArea}
                        placeholder={placeholder}
                        multiline
                        resizable={false}
                        borderless
                        value={question}
                        onChange={(ev, newValue) => onQuestionChange(ev, newValue)}
                        onKeyDown={onEnterPress}
                    />

                </Stack>
            </Stack>
            <div className={styles.questionInputSendButtonContainer}
                role="button"
                tabIndex={0}
                aria-label="Ask question button"
                onClick={sendQuestion}
                onKeyDown={e => e.key === "Enter" || e.key === " " ? sendQuestion() : null}
                style={{
                    background: sendQuestionDisabled ? "#E3E1E1" : "#517D7F",
                }}
            >
                {sendQuestionDisabled ?
                    <Send16Filled className={styles.questionInputSendButtonDisabled} />
                    :
                    <Send16Filled className={styles.questionInputSendButton} />
                }
            </div>
        </div>
    );
};
