import styled from "styled-components";

export const ContentChat = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  background: ${props => props.theme.colors.background};
  border-radius: 0 20px 20px 0;
  padding: 85px 80px 14px;

  h1, h2, p, span{
    margin: 0;
  }

  i, figure, button{
    cursor: pointer;
  }

  > div:last-child{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 65px;
    height: 90%;
  }

  .modalTextContainer{
    
    div, h2{
      color: ${props => props.theme.colors.color};
    }
  }
`

export const Welcome = styled.div`
  display: flex;
  gap: 27px;

  img{
    width: 53px;
    height: 53px;
  }

  div{
    display: flex;
    flex-direction: column;
    gap: 15px;

    p{
      max-width: 365px;
    }

    span{
      font-size: 10px;
      color: #B7B7B7;
    }
  }
`

export const QuestionSuggestions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;

  p{
    font-size: 12px;
  }

  div{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;

    button{
      border: 1px solid;
      border-radius: 100px;
      padding: 18px 34px;
      background: ${props => props.theme.colors.backgroundQuestion};
      color: ${props => props.theme.colors.color};
      border-color: ${props => props.theme.colors.backgroundQuestion};

      &:hover{
        border: 1px solid;
      }
    }
  }
`

export const Question = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  div{
    display: flex;
    align-items: center;
    height: 85px;
    background: ${props => props.theme.colors.backgroundQuestionInput};
    border: 1px solid ${props => props.theme.colors.borderSection};
    border-radius: 10px;
    box-shadow: 3px 5px 12px -10px;
    padding: 5px;

    textarea{
      width: 100%;
      height: 100%;
      background: transparent;
      color: ${props => props.theme.colors.color};
      border: none;
      border-radius: 10px;
      padding: 10px 17px;
      resize: none;

      &:focus-visible{
        outline: none;
      }
    }

    figure{
      background: ${props => props.theme.colors.backgroundButton};
      border-radius: 5px;
      margin: 0 12px 0;

      i{
        color: ${props => props.theme.colors.colorButton};
        padding: 12px 12px 12px 14px;
      }

      &:hover{
        opacity: 0.9;
      }
    }
  }

  span{
    text-decoration: underline;
    color: ${props => props.theme.colors.icon} !important;
    padding-left: 22px;
    cursor: pointer;

    &:hover{
      opacity: 0.9;
    }
  }

  .ms-layer{
    display: none;
  }

`

export const ChatMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  overflow-y: auto;
  margin-top: 24px;

  .chatMessageUser {
    display: flex;
    justify-content: flex-end;
    max-width: 80%;
    align-self: end;
  }

  .chatMessageUserMessage {
    border-radius: 100px;
    padding: 18px 34px;
    background: ${props => props.theme.colors.backgroundQuestion};
    color: ${props => props.theme.colors.color};
  }

  .chatMessageGpt {
    max-width: 80%;
    display: flex;
    border-radius: 8px;
  }

  .chatMessageError {
    padding: 10px 15px 10px 15px;
    background: ${props => props.theme.colors.backgroundConfig};
    border-radius: 8px;
    box-shadow: rgba(182, 52, 67, 1) 1px 2px 2px, rgba(182, 52, 67, 1) 0px 0px 0px;
    width: fit-content;
  }

  .chatMessageErrorContent {
    line-height: 22px;
    align-items: center;
    gap: 12px;
  }

  .stopGeneratingContainer {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 16px;
    gap: 4px;
    width: fit-content;
    height: 32px;
    border: 1px solid ${props => props.theme.colors.backgroundQuestionInput};
    border-radius: 16px;
    margin-top: auto;
    align-self: center;
    cursor: pointer;
  }

  .stopGeneratingIcon {
    width: 14px;
    height: 14px;
    color: ${props => props.theme.colors.color};
  }

  .stopGeneratingText {
    height: 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color:${props => props.theme.colors.color};
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .answerContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 6px;
    background: ${props => props.theme.colors.background};
    border-radius: 8px;
    padding: 8px;

    p, span{
      color: ${props => props.theme.colors.color};
    }

    &:focus-visible{
      outline: none;
    }
  }

  .answerText{

    a{
      color: ${props => props.theme.colors.icon};

      &:hover{
        opacity: 0.9;
      }
    }
  }

  .reportFormLink{
    color: ${props => props.theme.colors.icon};
    padding-left: 0;
    padding-right: 0;
  }

  .referenceList {
    display: flex;
    flex-flow: wrap column;
    gap: 4px;
    max-height: 150px;
    margin-top: 8px;

    span{
      color: ${props => props.theme.colors.icon}
    }

    div{
      color: ${props => props.theme.colors.color};
    }
  }

  .answerText {
    color: ${props => props.theme.colors.color};
  }

  .citationPanelContent {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    color: ${props => props.theme.colors.color};
  } 
`