import { Stack } from "@fluentui/react";
import styles from './Models.module.css';
import { TableComponent } from "../../../components/Table/TableComponent";
import { on } from "events";
import { SimpleButton } from "../../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { AIModel, ResultModalProps, deleteAIModelApi, listAIModelsApi } from "../../../api";
import { getAccessToken } from "../../../authConfig";
import { Modal } from "../../../components/modal/Modal";

const columns = [
    { columnKey: "id", label: "Id do modelo", attribute: "id", filterable: true },
    { columnKey: "host", label: "Host do modelo", attribute: "host", filterable: true },
    { columnKey: "model", label: "Nome do modelo", attribute: "model", filterable: true },
    { columnKey: "actions", label: "Actions" },
];

const items = [
    {
        host: "azure",
        id: "e557cc69-d55d-4a8b-be6a-b6e48398d5af",
        model: "gpt-4"
    },
    {
        host: "azure",
        id: "4ba22955-c170-478c-89dc-5edf7eacb679",
        model: "gpt-3.5-turbo"
    },
    {
        host: "openai",
        id: "e557cc69-d55d-4a8b-be6a-456gdsfh4gfd",
        model: "gpt-4-turbo"
    }
];

const Models = () => {
    const navigation = useNavigate();
    const { instance } = useMsal();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [models, setModels] = useState<AIModel[]>([]);
    const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);
    const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState<boolean>(false);
    const [deleteItem, setDeleteItem] = useState<AIModel | null>(null);
    const [deleteResult, setDeleteResult] = useState<ResultModalProps>({ showResult: false, titleResult: '', result: '', typeResult: undefined });

    useEffect(() => {
        try {
            const fetchData = async () => {
                setIsLoading(true);
                const response = await listAIModelsApi(await getAccessToken(instance));
                if (response?.body) {
                    console.log('response com body')
                    const result: AIModel[] = await response.json();
                    console.log(result)
                    setModels(result);
                }
                else {
                    console.log('response sem body')
                    setModels([]);
                }
                setIsLoading(false);
            };
            Promise.resolve(fetchData());
        } catch (error) {
            console.log('error', error);
        }
    }, []);

    const editHanler = (item: any) => {
        navigation('/admin/models/form', { state: { item } });
    }

    const deleteHandler = async () => {
        if (deleteItem) {
            setIsDeleteLoading(true);
            const response = await deleteAIModelApi(await getAccessToken(instance), deleteItem.id);
            setIsDeleteLoading(false);
            setIsDeleteDialogVisible(false);
            if (response.status >= 200 && response.status < 300) {
                setModels(prevModels => prevModels.filter(model => model.id !== deleteItem.id));
                setDeleteResult({
                    showResult: true,
                    titleResult: 'Modelo excluído',
                    result: 'Modelo excluído com sucesso.',
                    typeResult: 'info'
                });
            } else {
                setDeleteResult({
                    showResult: true,
                    titleResult: 'Erro',
                    result: 'Ocorreu um erro ao excluir o modelo',
                    typeResult: 'error'
                });
            }
            // Adiciona um delay antes de alterar showResult para false
            setTimeout(() => {
                setDeleteResult(prevResult => ({
                    ...prevResult,
                    showResult: false
                }));
            }, 2000);
            setDeleteItem(null);
        }
    };

    const showDeleteDialog = (item: AIModel) => {
        setDeleteItem(item);
        setIsDeleteDialogVisible(true);
    };

    const closeDeleteDialog = () => {
        setIsDeleteDialogVisible(false);
        setDeleteItem(null);
    };

    const createButton = () => {
        navigation('/admin/models/form');
    }

    return (
        <>
            <Stack className={styles.container}>
                <div className={styles.createButton}>
                    <SimpleButton onClickFunction={createButton} buttonText="Criar" backgroundColor="#517D7F" textColor="white" />
                </div>
                <TableComponent key={models.length} loading={isLoading} columns={columns} items={models} onEditFunction={editHanler} onDeleteFunction={showDeleteDialog} />
            </Stack>

            <Modal
                open={isDeleteDialogVisible}
                closeModalFunc={closeDeleteDialog}
                doSomethingFunc={deleteHandler}
                title="Confirmar Exclusão"
                text="Tem certeza de que deseja excluir este modelo?"
                buttonDoSomethingText="Excluir"
                buttonCloseText="Cancelar"
                modalType="info"
            />
            <Modal
                open={isDeleteLoading}
                title="Excluindo modelo"
                text="Excluindo modelo..."
                modalType="wait"
            />
            <Modal
                open={deleteResult.showResult}
                title={deleteResult.titleResult}
                text={deleteResult.result}
                modalType={deleteResult.typeResult}
            />
        </>
    )
}

export default Models;