import { NavigateFunction } from "react-router-dom";

export function clearLocalStorageMSAL() {
  const terms = localStorage.getItem('acceptedTerms');

  localStorage.clear();

  if (terms) {
    localStorage.setItem('acceptedTerms', terms);
  }
}

export function handleGetTokenError(navigate: NavigateFunction) {
  navigate(`/login?force=true&redirect=${window.location.href}`);
}