import styles from "./Button.module.css";

interface Props {
    disabled: boolean;
    text?: string;
    onClickAct?: () => void;
}

export const ButtonNewChat = (props : Props) => {

    return (
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <button className={props.disabled ? styles.newChatDisabled : styles.newChat} disabled={props.disabled} onClick={props.onClickAct}>{props.text}</button>
      </div>
    );
};
